import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { AccessControl } from '../../components/Utils/AccessControl'
import EditIcon from '@mui/icons-material/Edit'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Skeleton,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { authActions, usuarioAction, tipoUsuarioAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { TIPO_USUARIO, phoneMask, history, stringUtils, MANUAIS_CATEGORIAS } from '../../utils'
import { Page } from 'components'
import HelpIcon from '@mui/icons-material/Help';
import { isEmpty } from 'lodash'
import SaveIcon from '@mui/icons-material/Save';
import CustomButton from '../../components/CustomButton/CustomButton'
import { HelpModal, EditorHeader } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const UsuarioEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [ready, setReady] = React.useState(false);
  const [emailEditable, setEmailEditable] = useState(false);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const tipoUsuario = useSelector((state) => state.tipoUsuario.tipoUsuario)

  useEffect(() => {
    dispatch(usuarioAction.clear())
    dispatch(tipoUsuarioAction.getTiposUsuarioSelect())

    if (params.id) {
      dispatch(usuarioAction.getUsuarioById(params.id))
    } else {
      dispatch(usuarioAction.clear())
    }

  }, []);

  useEffect(() => {

  }, [ready, props.usuario.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(usuarioAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      nome: props.usuario.nome,
      senha: props.usuario.senha,
      sobrenome: props.usuario.sobrenome,
      email: props.usuario.email.toLowerCase(),
      //SE FOR CRIAR USUÁRIO NOVO, VAI SER ADMIN. OS OUTROS USUÁRIOS SÃO CRIADOS NAS OUTRAS TELAS
      idTipoUsuario: params.id ? props.usuario.idTipoUsuario : TIPO_USUARIO.ADMIN,
      caminhoCnh: props.usuario.caminhoCnh,
      celular: props.usuario.celular?.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
      cnh: props.usuario.cnh,
      matricula: props.usuario.matricula,
      codigoIntegracaoTelemetria: props.usuario.codigoIntegracaoTelemetria,
      telefone: props.usuario.telefone?.split('-').join('').replace('(', '').replace(')', '').replace(' ', '')
    }

    if (params.id) {
      dispatch(usuarioAction.editUsuarioInfo(params.id, payload, true))
    } else {
      dispatch(usuarioAction.createUsuario(payload))
    }
  };

  const handleSubmitUsuarioEmail = (event) => {

    let payload = {
      id: params.id,
      email: props.usuario.email
    }

    if (params.id) {
      dispatch(usuarioAction.editUsuarioEmailInfo(params.id, payload, true))
    }
  };

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Usuário'}</b>
      </Typography>
    )
  }

  function SegHeaderEmail() {
    if (params.id) {
      return <EditEmailText />
    }
    return <InsertEmailText />
  }

  function SegHeaderToken() {
    if (params.id) {
      return <Typography variant="h5">
        <b>{'Tokens'}</b>
      </Typography>
    }
  }

  function InsertEmailText() {
    return (
      <Typography variant="h5">
        <b>{'Cadastrar E-mail'}</b>
      </Typography>
    )
  }

  function EditEmailText() {
    return (
      <Typography variant="h5">
        <b>{authActions.isGestor() ? 'Editar E-mail' : 'E-mail'}</b>
      </Typography>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Usuários - Editar Usuário'
          : 'Gerência de Usuários - Novo Usuário'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formUsuario"
                onSubmit={(event) => handleSubmit(event)}
              >
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <CardHeader
                    subheader="Cadastro de Usuários"
                    title={(
                      params.id ?
                        <EditorHeader title='Editar Usuário' tabela='Usuario' chave={params.id} props={props} />
                        :
                        <InsertText />
                    )}
                  />
                  {!authActions.isEmpresa() && !authActions.isPrefeitura() ?
                    <Grid item lg={1} md={1} xl={1} xs={12}>
                      <IconButton
                        aria-label="Ajuda"
                        component="a"
                        onClick={() =>
                          handleOpenModalHelp()
                        }
                        size="small"
                      >
                        <Tooltip title={'Ajuda'}>
                          <Tooltip>
                            <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                          </Tooltip>
                        </Tooltip>
                      </IconButton>
                    </Grid>
                    : null}
                </Grid>
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('nome')}
                        value={props.usuario.nome || ''}
                        variant="outlined"
                        validators={['required', 'maxStringLength: 60']}
                        errorMessages={['Campo obrigatório!', 'Tamanho máximo do campo só pode ser de até 60 caracteres!']}

                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="sobrenome"
                        label="Sobrenome *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('sobrenome')}
                        value={props.usuario.sobrenome || ''}
                        variant="outlined"
                        validators={['required', 'maxStringLength: 60']}
                        errorMessages={['Campo obrigatório!', 'Tamanho máximo do campo só pode ser de até 60 caracteres!']}
                      />
                    </Grid>
                    {!params.id &&
                      <Grid item lg={6} md={6} xl={6} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="E-mail para acesso ao sistema *"
                            value={props.usuario.email || ''}
                            className={classes.textField}
                            onInput={stringUtils.toInputLowerCase}
                            onChange={handleChange('email')}
                            id="email"
                            name="email"
                            autoComplete="email"
                            validators={['required', 'isEmail']}
                            errorMessages={[
                              'Você precisa informar o e-mail!',
                              'E-mail inválido!'
                            ]}
                            disabled={props.usuario.id != 0}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12} style={{ marginTop: 0, paddingTop: 0 }}>
                          <Typography style={{ fontSize: 11, paddingLeft: 2 }}>
                            <b>O usuário receberá um e-mail posteriormente para criar uma senha</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                    {params.id ?
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <SelectValidator
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          variant="outlined"
                          id="idTipoUsuario"
                          label="Tipo Usuário *"
                          onChange={handleChange('idTipoUsuario')}
                          disabled={!authActions.isGestor()}
                          margin="normal"
                          value={props.usuario.idTipoUsuario}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          displayEmpty
                          labelId="idTipoUsuario"
                        >
                          <MenuItem disabled value="">
                            <em>Tipo Usuário</em>
                          </MenuItem>
                          {!isEmpty(tipoUsuario) &&
                            undefined !== tipoUsuario &&
                            tipoUsuario.length &&
                            tipoUsuario.map((row) => (
                              <MenuItem key={row.value} value={row.value}>
                                {row.text}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      </Grid>
                      :
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <SelectValidator
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          variant="outlined"
                          id="idTipoUsuario"
                          label="Tipo Usuário *"
                          disabled
                          margin="normal"
                          value={TIPO_USUARIO.ADMIN}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          displayEmpty
                          labelId="idTipoUsuario"
                        >
                          <MenuItem disabled value="">
                            <em>Tipo Usuário</em>
                          </MenuItem>
                          <MenuItem selected value={TIPO_USUARIO.ADMIN}>ADMINISTRADOR</MenuItem>
                        </SelectValidator>
                      </Grid>
                    }
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="celular"
                        label="Celular "
                        margin="normal"
                        onChange={handleChange('celular')}
                        value={phoneMask(props.usuario.celular) || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="telefone"
                        label="Telefone "
                        margin="normal"
                        onChange={handleChange('telefone')}
                        value={phoneMask(props.usuario.telefone) || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="cnh"
                        label="CNH"
                        margin="normal"
                        type="number"
                        onChange={handleChange('cnh')}
                        validators={['maxStringLength: 11']}
                        errorMessages={['Tamanho máximo do campo só pode ser de até 11 caracteres!']}
                        value={props.usuario.cnh || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="caminhoCnh"
                        label="Link CNH"
                        margin="normal"
                        onChange={handleChange('caminhoCnh')}
                        value={props.usuario.caminhoCnh || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="matricula"
                        label="Matrícula"
                        type="number"
                        margin="normal"
                        validators={['maxStringLength: 11']}
                        errorMessages={['Tamanho máximo do campo só pode ser de até 11 caracteres!']}
                        onChange={handleChange('matricula')}
                        value={props.usuario.matricula || ''}
                        variant="outlined"
                      />
                    </Grid>
                    {authActions.isGestor() ?
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="codigoIntegracaoTelemetria"
                          label="Código de Integração Telemetria"
                          type="number"
                          margin="normal"
                          validators={['maxStringLength: 10']}
                          errorMessages={['Tamanho máximo do campo só pode ser de até 10 caracteres!']}
                          onChange={handleChange('codigoIntegracaoTelemetria')}
                          value={props.usuario.codigoIntegracaoTelemetria || ''}
                          variant="outlined"
                        />
                      </Grid>
                      : null}
                    {(props.usuario && props.usuario.dataHoraUltimoAcesso ?
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="dataHoraUltimoAcesso"
                          label="Data/Hora do Último Acesso"
                          margin="normal"
                          value={props.usuario.dataHoraUltimoAcesso ? moment(props.usuario.dataHoraUltimoAcesso).format("DD/MM/YYYY HH:mm") : ''}
                          variant="outlined"
                        />
                      </Grid>
                      :
                      null
                    )}
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => history.push('/usuarios')}
                    >
                      Cancelar
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      dark={true}
                      disabled={props.usuario.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.usuario.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.usuario.isLoading ? 'Atualizando usuário...' : 'Atualizar') : (props.usuario.isLoading ? 'Salvando usuário...' : 'Salvar')}
                    </CustomButton>
                  </Grid>
                </CardActions>
              </ValidatorForm>
              {params.id &&
                <Grid
                  {...rest}
                  className={clsx(classes.root, className)}
                  container
                  spacing={3}
                >
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{ marginTop: 24 }}>
                    <Grid item md={12} xs={12}>
                      <Card>
                        <ValidatorForm
                          className={classes.form}
                          ref={formRef}
                          id="formUsuarioEmail"
                          onSubmit={(event) => handleSubmitUsuarioEmail(event)}
                        >
                          <CardHeader
                            title={<SegHeaderEmail />}
                            subheader={authActions.isGestor() ? 'Altera somente o e-mail de acesso ao sistema do usuário' : 'Para acesso ao sistema'}>
                          </CardHeader>
                          <Divider />
                          <CardContent lg={12} md={12} xl={12} xs={12}>
                            <Grid container spacing={2}>
                              <Grid item lg={6} md={6} xl={6} xs={12}>
                                <TextValidator
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  label="E-mail *"
                                  value={props.usuario.email || ''}
                                  className={classes.textField}
                                  onInput={stringUtils.toInputLowerCase}
                                  onChange={handleChange('email')}
                                  id="email"
                                  name="email"
                                  autoComplete="email"
                                  validators={['required', 'isEmail']}
                                  errorMessages={[
                                    'Você precisa informar o e-mail',
                                    'E-mail inválido'
                                  ]}
                                  disabled={props.usuario.id != 0 && emailEditable == false}
                                />
                              </Grid>
                              <AccessControl
                                rule={'usuariosEmail.edit'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <Grid item lg={1} md={1} xl={1} xs={12}>
                                    <IconButton
                                      style={{ marginTop: 20 }}
                                      color="inherit"
                                      onClick={() => setEmailEditable(true)}
                                    >
                                      <Tooltip title={'Liberar Edição do E-mail'}>
                                        <Tooltip >
                                          <EditIcon />
                                        </Tooltip>
                                      </Tooltip>
                                    </IconButton>
                                  </Grid>
                                )}
                              />
                            </Grid>
                          </CardContent>
                          {authActions.isGestor() &&
                            <>
                              <Divider />
                              <CardActions>
                                <Grid item lg={12} md={12} xl={12} xs={12} style={{
                                  display: 'flex',
                                  alignItems: 'right',
                                  flexDirection: 'row',
                                  textAlign: 'right',
                                  justifyContent: 'right'
                                }}>
                                  <CustomButton
                                    variant="outlined"
                                    onClick={() => history.push('/usuarios')}
                                  >
                                    Cancelar
                                  </CustomButton>
                                  <CustomButton
                                    type="submit"
                                    variant="contained"
                                    dark={true}
                                    disabled={props.usuario.isLoading}
                                    startIcon={<SaveIcon />}
                                    endIcon={props.usuario.isLoading && <CircularProgress size={24} />}
                                  >
                                    {params.id ? (props.usuario.isLoading ? 'Atualizando email...' : 'Atualizar e-mail') : (props.usuario.isLoading ? 'Salvando email...' : 'Salvar e-mail')}
                                  </CustomButton>
                                </Grid>
                              </CardActions>
                            </>
                          }
                        </ValidatorForm>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              }
              {params.id && authActions.isGestor() &&
                <Grid
                  {...rest}
                  className={clsx(classes.root, className)}
                  container
                  spacing={3}
                >
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{ marginTop: 24 }}>
                    <Grid item md={12} xs={12}>
                      <Card>
                        <CardHeader
                          title={<SegHeaderToken />}
                          subheader={'Lista os tokens vinculados a este usuário'}>
                        </CardHeader>
                        <Divider />
                        <CardContent lg={12} md={12} xl={12} xs={12}>
                          <Grid container spacing={2}>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell><strong>TOKEN</strong></TableCell>
                                    <TableCell><strong>STATUS</strong></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {props.usuario && props.usuario.usuariosDispositivos?.length > 0
                                    && props.usuario?.usuariosDispositivos != null ? props.usuario?.usuariosDispositivos.map((n) => {
                                      return (
                                        <TableRow hover >
                                          <TableCell lg={12} md={12} xl={12} xs={12}
                                            style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                            {n.token ? n.token : ''}
                                          </TableCell>
                                          <TableCell lg={12} md={12} xl={12} xs={12}
                                            style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                            {n.ativo ? 'ATIVO' : 'INATIVO'}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    }) :
                                    props.usuario?.isLoading ?
                                      Array.apply(null, { length: 3 }).map((e, i) => (
                                        <TableRow hover>
                                          <TableCell component="th" scope="row">
                                            <Skeleton animation="wave" />
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                            <Skeleton animation="wave" />
                                          </TableCell>
                                        </TableRow>
                                      ))
                                      : <TableRow>
                                        <TableCell colSpan={2}>
                                          <Typography align="center" variant="subtitle2">
                                            Nenhum dispositivo vinculado até o momento
                                          </Typography>
                                        </TableCell>
                                      </TableRow>}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.EDICAO_USUARIO}
          />
          :
          null
        )
      }
    </Page >
  )
}

UsuarioEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedUsuarioEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(UsuarioEdit))
)
export { connectedUsuarioEditPage as UsuarioEdit }
