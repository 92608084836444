import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { withStyles } from '@mui/styles'
import getInitials from 'utils/getInitials'
import CustomButton from '../../components/CustomButton/CustomButton'
import Swal from 'sweetalert2';
import { ImageUploader } from 'components'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  Backdrop,
  Badge,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
  authActions,
  veiculoAction,
  usuarioAction,
  orgaoAction,
  subOrgaoAction,
  marcaAction,
  tipoCombustivelAction,
  tipoVeiculoAction,
  parametroAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { uploadImage } from '_services'
import { useConfirm } from 'material-ui-confirm'
import { errorsUploadImage } from 'utils'
import { placaMask, stringUtils } from '../../utils'
import { isEmpty } from 'lodash'
import SaveIcon from '@mui/icons-material/Save';

const SwitchBlack = styled(Switch)(({ theme }) => ({
  alignItems: 'center',
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#000000',
    }
  }
}));

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(1),
    justifyContent: 'space-between'
  },
  avatar: {
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer',
    objectFit: 'cover'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const VeiculoOrgaoEditModal = ({ modal, toggle, props, idorgao, orgao, idveiculo }) => {

  const dispatch = useDispatch()
  const { classes, veiculo, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [idUsuario, setIdUsuario] = useState(0);

  const confirm = useConfirm()
  const [images, setImages] = useState([])
  const [imagem, setImagem] = useState('')
  const [open, setOpen] = useState(false)

  const [textUsuario, setTextUsuario] = useState('');

  const [valueMarca, setValueMarca] = useState(null);
  const [inputValueMarca, setInputValueMarca] = useState('');

  const [valueTipoCombustivel, setValueTipoCombustivel] = useState(null);
  const [inputValueTipoCombustivel, setInputValueTipoCombustivel] = useState('');

  const [valueUsuario, setValueUsuario] = useState(null);
  const [inputValueUsuario, setInputValueUsuario] = useState('');

  const { usuario } = useSelector((state) => state.usuario)

  const subOrgaos = useSelector((state) => state.subOrgao.subOrgao)
  const tiposVeiculos = useSelector((state) => state.tipoVeiculo.tipoVeiculo)
  const parametro = props.parametro && props.parametro.valor ? parseInt(props.parametro.valor) : null;

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const handleClose = () => {
    toggle();
  }

  const { marca } = useSelector((state) => state.marca)
  const { tipoCombustivel } = useSelector((state) => state.tipoCombustivel)

  const optionsMarcas = marca.map(u => ({ id: u.value, nome: u.text }));
  const optionsCombustiveis = tipoCombustivel.map(u => ({ id: u.value, nome: u.text }));
  const optionsUsuarios = usuario.map(u => ({ id: u.value, nome: u.text }));

  useEffect(() => {
    if (valueMarca) {
      handleChangeAutoComplete(valueMarca, marca, 'marca')
    }
  }, [valueMarca])

  useEffect(() => {
    if (valueTipoCombustivel) {
      handleChangeAutoComplete(valueTipoCombustivel, tipoCombustivel, 'tipoCombustivel')
    }
  }, [valueTipoCombustivel])

  useEffect(() => {
    if (valueUsuario) {
      handleChangeAutoComplete(valueUsuario, usuario, 'usuario')

      setIdUsuario(valueUsuario.id);
      setTextUsuario(valueUsuario.nome)
    }
  }, [valueUsuario])

  const handleChangeAutoComplete = (obj, array, nameArray) => {
    if (obj != null) {
      let selected = array.filter(
        (item) => item.value == obj.id
      )

      if (nameArray == 'orgao') {
        dispatch(orgaoAction.changeDetailsOrgao(selected[0]))
      } else if (nameArray == 'tipoCombustivel') {
        dispatch(tipoCombustivelAction.changeDetailsTipoCombustivel(selected[0]))
      } else if (nameArray == 'marca') {
        dispatch(marcaAction.changeDetailsMarca(selected[0]))
      } else if (nameArray == 'usuario') {
        dispatch(usuarioAction.changeDetailsUsuario(selected[0]))
      }

    } else {

    }
  }

  useEffect(() => {
    if (params.id != undefined && props.veiculo.idMarca != 0) {
      setValueMarca({
        'id': marca?.find(m => m.value == props.veiculo.idMarca)?.value,
        'nome': marca?.find(m => m.value == props.veiculo.idMarca)?.text
      })
    }
  }, [props.veiculo.idMarca])

  useEffect(() => {
    if (params.id != undefined && props.veiculo.idTipoCombustivel != 0) {
      setValueTipoCombustivel({
        'id': tipoCombustivel?.find(m => m.value == props.veiculo.idTipoCombustivel)?.value,
        'nome': tipoCombustivel?.find(m => m.value == props.veiculo.idTipoCombustivel)?.text
      })
    }
  }, [props.veiculo.idTipoCombustivel])

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Não'
    }).then(() => {
      let payload = {
        ...veiculo,
        caminhoFoto: null
      }
      dispatch(
        veiculoAction.editVeiculoInfo(veiculo.id, payload, false)
      )
      var event2 = {
        type: 'select',
        target: {
          value: null
        }
      };
      dispatch(veiculoAction.onChangeProps('caminhoFoto', event2))
      setImagem(null)
    })
  }

  const onChangeImage = (imageList) => {
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {

        let payload = {
          ...veiculo,
          caminhoFoto: response.toString()
        }

        if (veiculo.id !== 0) {
          dispatch(
            veiculoAction.editVeiculoInfo(veiculo.id, payload, false)
          )
        }

        setTimeout(function () {

          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(veiculoAction.onChangeProps('caminhoFoto', event2))

          setImagem(response)
          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  useEffect(() => {
    setImagem(veiculo.caminhoFoto)
  }, [veiculo.caminhoFoto, open]);

  useEffect(() => {
  }, [open, imagem]);

  useEffect(() => {

    dispatch(tipoVeiculoAction.getTipoVeiculoSelect())
    dispatch(tipoCombustivelAction.getTipoCombustivelSelect())
    dispatch(marcaAction.getMarcaSelect())
    dispatch(orgaoAction.getOrgaoSelect())

    if (idveiculo != 0) {
      dispatch(veiculoAction.getVeiculoById(idveiculo))
    } else {
      dispatch(veiculoAction.clear())
      dispatch(veiculoAction.clearUsuariosVeiculos())
    }

    if (idorgao != undefined && idorgao !== 0 && idorgao !== '') {
      dispatch(subOrgaoAction.getSubOrgaoByIdOrgao(idorgao))
    }

  }, []);

  useEffect(() => {
    dispatch(parametroAction.getTamanhoMaximoEnvioArquivos())

    if (idorgao != 0) {
      dispatch(usuarioAction.getUsuarioMotoristaByOrgaoSelect(idorgao))
    } else {
      toast.error('Selecione um órgão');
    }
  }, []);

  useEffect(() => {
  }, [ready, props.veiculo.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(veiculoAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: idveiculo,
      ano: props.veiculo.ano,
      placa: props.veiculo.placa ? props.veiculo.placa.split('-').join('').replace('-', '') : '',
      renavam: props.veiculo.renavam,
      hodometro: props.veiculo.hodometro,
      numeroChassis: props.veiculo.numeroChassis,
      idMarca: valueMarca ? valueMarca.id : props.veiculo.idMarca,
      idTipoVeiculo: props.veiculo.idTipoVeiculo,
      idTipoCombustivel: valueTipoCombustivel ? valueTipoCombustivel.id : props.veiculo.idTipoCombustivel,
      modelo: props.veiculo.modelo,
      caminhoFoto: props.veiculo.caminhoFoto,
      idOrgao: idorgao ? idorgao : props.veiculo.idOrgao,
      idSubOrgao: (props.veiculo.idSubOrgao == '' || props.veiculo.idSubOrgao == 0) ? null : props.veiculo.idSubOrgao,
      usuariosVeiculos: props.veiculo.usuariosVeiculos,
      telemetria: props.veiculo.telemetria,
      gestaoAbastecimento: props.veiculo.gestaoAbastecimento
    }


    if (idveiculo) {
      dispatch(veiculoAction.editVeiculoOrgaoInfo(idveiculo, payload))
      handleClose()
    } else {
      dispatch(veiculoAction.createVeiculoOrgao(payload))
      handleClose()
    }

  };

  const handleAddOnList = e => {

    if (props.veiculo.usuariosVeiculos.filter(ts => ts.idUsuario == parseInt(idUsuario)).length > 0) {
      toast.error('Erro ao adicionar! Este usuário já existe!')
    }
    else {

      if (idUsuario != '' && idUsuario != '0') {
        props.veiculo.usuariosVeiculos.push({ 'idUsuario': idUsuario, 'idVeiculo': props.veiculo.id, 'usuario': { 'nome': textUsuario } });
        forceUpdate(n => !n);
        setIdUsuario(0);

      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Erro ao salvar',
          showCloseButton: true,
          showConfirmButton: true,
          html: '<div style="font-size: 14px; text-align: center">Informe ao menos um usuário!</div>'
        });
      }
    }
  };

  const handleRemoveOnList = id => {
    props.veiculo.usuariosVeiculos = props.veiculo.usuariosVeiculos.filter(ts => ts.idUsuario != parseInt(id));
    forceUpdate(n => !n);
  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Veículo'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='lg'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <Grid
            {...rest}
            className={clsx(classes.root, className)}
            container
            spacing={3}
          >
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Grid item md={12} xs={12}>
                <Backdrop className={classes.backdrop} open={open}>
                  <Grid
                    container
                    spacing={3}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                  >
                    <Grid item>
                      <CircularProgress color="primary" />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" color="textPrimary">
                        Enviando foto...
                      </Typography>
                    </Grid>
                  </Grid>
                </Backdrop>
                <ImageUploader
                  images={images}
                  onChangeImage={onChangeImage}
                  onError={errorsUploadImage}
                  acceptType={['jpeg', 'jpg', 'png', 'webp', 'jfif', 'bmp', 'tiff']}
                  maxFileSize={parametro}
                  resolutionWidth={5000}
                  resolutionHeight={5000}
                  resolutionType={"less"}
                  removeImage={removeImage}
                  props={veiculo}
                  imagem={imagem}
                  getInitials={getInitials}
                  classes={classes}
                  rest={rest}
                  className={className}
                />
              </Grid>
            </Grid>
            <Grid item lg={8} md={6} xl={9} xs={12}>
              <Grid item md={12} xs={12}>
                <Card>
                  <ValidatorForm
                    className={classes.form}
                    ref={formRef}
                    id="formVeiculo"
                    onSubmit={(event) => handleSubmit(event)}
                  >
                    <CardHeader
                      subheader="Cadastro de Veículos"
                      title={<SegHeader />}
                    />
                    <Divider />
                    <CardContent lg={12} md={12} xl={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="ano"
                            type="number"
                            label="Ano *"
                            margin="normal"
                            onChange={handleChange('ano')}
                            value={props.veiculo.ano || ''}
                            variant="outlined"
                            validators={['required', 'maxStringLength: 4']}
                            errorMessages={['Campo obrigatório!', 'Ano inválido!']}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="placa"
                            label="Placa *"
                            margin="normal"
                            onInput={stringUtils.toInputUppercase}
                            onChange={handleChange('placa')}
                            value={props.veiculo.placa ? placaMask(props.veiculo.placa) : '' || ''}
                            variant="outlined"
                            validators={['required', 'maxStringLength: 8']}
                            errorMessages={['Campo obrigatório!', 'Placa só pode ter no máximo 7 caracteres!']}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="hodometro"
                            type="number"
                            label="Hodômetro"
                            margin="normal"
                            onChange={handleChange('hodometro')}
                            value={props.veiculo.hodometro || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12} style={{ marginTop: 16 }}>
                          <Autocomplete
                            value={valueMarca}
                            onChange={(event, newValue) => {
                              setValueMarca(newValue);
                            }}
                            inputValue={inputValueMarca}
                            onInputChange={(event, newInputValueMarca) => {
                              setInputValueMarca(newInputValueMarca);
                            }}
                            id="controllable-states-demo"
                            options={optionsMarcas}
                            margin="normal"
                            variant="outlined"
                            defaultValue={valueMarca}
                            getOptionLabel={(optionMarca) => (optionMarca ? optionMarca.nome : '')}
                            renderInput={(params) => <TextField {...params} label="Marca * " fullWidth />}
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="modelo"
                            label="Modelo *"
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            onInput={stringUtils.toInputUppercase}
                            margin="normal"
                            onChange={handleChange('modelo')}
                            value={props.veiculo.modelo || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <SelectValidator
                            className={classes.textField}
                            variant="outlined"
                            id="idTipoVeiculo"
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            onChange={handleChange('idTipoVeiculo')}
                            label="Tipo do Veículo *"
                            value={props.veiculo.idTipoVeiculo || ''}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            displayEmpty
                            labelId="idTipoVeiculo"
                          >
                            <MenuItem disabled value="">
                              <em>Tipo do Veículo *</em>
                            </MenuItem>
                            {!isEmpty(tiposVeiculos) &&
                              undefined !== tiposVeiculos &&
                              tiposVeiculos.length &&
                              tiposVeiculos.map((row, index) => (
                                <MenuItem key={index} value={row.value}>
                                  {row.text}
                                </MenuItem>
                              ))}
                          </SelectValidator>
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12} style={{ marginTop: 16 }}>
                          <Autocomplete
                            value={valueTipoCombustivel}
                            onChange={(event, newValue) => {
                              setValueTipoCombustivel(newValue);
                            }}
                            inputValue={inputValueTipoCombustivel}
                            onInputChange={(event, newInputValueTipoCombustivel) => {
                              setInputValueTipoCombustivel(newInputValueTipoCombustivel);
                            }}
                            id="controllable-states-demo"
                            options={optionsCombustiveis}
                            margin="normal"
                            variant="outlined"
                            defaultValue={valueTipoCombustivel}
                            getOptionLabel={(optionTipoCombustivel) => (optionTipoCombustivel ? optionTipoCombustivel.nome : '')}
                            renderInput={(params) => <TextField {...params} label="Tipo do Combustível * " fullWidth />}
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="renavam"
                            label="Renavam"
                            margin="normal"
                            onChange={handleChange('renavam')}
                            value={props.veiculo.renavam || ''}
                            variant="outlined"
                            validators={['maxStringLength: 11']}
                            errorMessages={['Renavam só pode ter no máximo 11 caracteres!']}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="numeroChassis"
                            type="number"
                            label="Número Chassis *"
                            margin="normal"
                            validators={['required', 'maxStringLength: 17']}
                            errorMessages={['Campo obrigatório', 'Número Chassis só pode ter no máximo 17 caracteres!']}
                            onChange={handleChange('numeroChassis')}
                            value={props.veiculo.numeroChassis || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <SelectValidator
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            variant="outlined"
                            id="idOrgao"
                            label="Órgão *"
                            margin="normal"
                            value={idorgao}
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            displayEmpty
                            disabled
                            labelId="idOrgao"
                          >
                            <MenuItem disabled value="">
                              <em>Órgão</em>
                            </MenuItem>
                            <MenuItem selected value={idorgao}>{orgao.nome}</MenuItem>
                          </SelectValidator>
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <SelectValidator
                            className={classes.textField}
                            variant="outlined"
                            id="idSubOrgao"
                            label="Sub Órgão"
                            value={props.veiculo.idSubOrgao}
                            onChange={handleChange('idSubOrgao')}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            displayEmpty
                            labelId="idSubOrgao"
                          >
                            <MenuItem value="">
                              <em>Selecione</em>
                            </MenuItem>
                            {!isEmpty(subOrgaos) &&
                              undefined !== subOrgaos &&
                              subOrgaos.length &&
                              subOrgaos.map((row, index) => (
                                <MenuItem key={index} value={row.value}>
                                  {row.text}
                                </MenuItem>
                              ))}
                          </SelectValidator>
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                          {/*se o orgao não tiver telemetria habilitada,
                        mostra o campo desabilitado*/}
                          {authActions.isGestor() ?
                            <FormControlLabel
                              control={
                                <SwitchBlack
                                  color="default"
                                  disabled={orgao?.telemetria != true}
                                  checked={props.veiculo.telemetria}
                                  onChange={handleChange('telemetria')}
                                />
                              }
                              label="Possui telemetria"
                            />
                            : null}
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                          {/*se o orgao não tiver gestão de abastecimento habilitado,
                          mostra o campo desabilitado*/}
                          {authActions.isGestor() ?
                            <FormControlLabel
                              control={
                                <SwitchBlack
                                  color="default"
                                  disabled={orgao?.gestaoAbastecimento != true}
                                  checked={props.veiculo.gestaoAbastecimento}
                                  onChange={handleChange('gestaoAbastecimento')}
                                />
                              }
                              label="Possui Gestão de Abastecimento"
                            />
                            : null}
                        </Grid>
                      </Grid>
                      <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                        <Divider />
                      </Grid>
                      <Grid container lg={12} md={12} xl={12} xs={12} spacing={2}>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <Typography component="h4" variant="subtitle" align="center">
                            Motoristas vinculados ao veículo
                          </Typography>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: 6 }}>
                          <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center', marginLeft: 20 }} >
                            <Autocomplete
                              value={valueUsuario}
                              onChange={(event, newValue) => {
                                setValueUsuario(newValue);
                              }}
                              inputValue={inputValueUsuario}
                              onInputChange={(event, newInputValueUsuario) => {
                                setInputValueUsuario(newInputValueUsuario);
                              }}
                              id="controllable-states-demo"
                              options={optionsUsuarios}
                              margin="normal"
                              variant="outlined"
                              defaultValue={valueUsuario}
                              getOptionLabel={(optionUsuario) => (optionUsuario ? optionUsuario.nome : '')}
                              renderInput={(params) => <TextField {...params} label="Selecione o Motorista " fullWidth />}
                              fullWidth
                            />
                          </Grid>
                          <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                            <Button style={{ width: '200px', marginLeft: '15px', backgroundColor: '#000' }} name="btnUsuarios" id="btnUsuarios" variant="contained" className="btn" component='a' onClick={handleAddOnList}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                          </Grid>
                          <Grid item lg={3} md={3} xl={3} xs={12}>
                            <div className="selectgroup selectgroup-pills" style={{ marginLeft: 20, alignItems: 'top' }}>
                              <Grid item lg={12} md={12} xl={12} xs={12}>
                                {
                                  (props.veiculo.usuariosVeiculos != null && props.veiculo.usuariosVeiculos.length > 0 ?
                                    props.veiculo.usuariosVeiculos.map(n => {
                                      return (
                                        (n.id !== '' ?
                                          <Button style={{ fontWeigth: 400, backgroundColor: '#F2D647', marginLeft: 7, marginRight: 7 }} onClick={() => handleRemoveOnList(n.idUsuario)} key={'button' + n.id}>
                                            <Badge style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7, color: '#000' }}>
                                              {(n.usuario ? n.usuario.nome : n.idUsuario)}
                                            </Badge>
                                            &nbsp;<FontAwesomeIcon icon={faTrashAlt} style={{ color: '#000' }} />
                                          </Button>
                                          : null)
                                      );
                                    })
                                    : null)
                                }
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                          * Campos obrigatórios
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} style={{
                        display: 'flex',
                        alignItems: 'right',
                        flexDirection: 'row',
                        textAlgin: 'right',
                        justifyContent: 'right'
                      }}>
                        <CustomButton
                          variant="outlined"
                          onClick={() => handleClose()}
                        >
                          Cancelar
                        </CustomButton>
                        <CustomButton
                          type="submit"
                          variant="contained"
                          dark={true}
                          disabled={props.veiculo.isLoading}
                          startIcon={<SaveIcon />}
                          endIcon={props.veiculo.isLoading && <CircularProgress size={24} />}
                        >
                          {idveiculo ? (props.veiculo.isLoading ? 'Atualizando veículo...' : 'Atualizar') : (props.veiculo.isLoading ? 'Salvando veículo...' : 'Salvar')}
                        </CustomButton>
                      </Grid>
                    </CardActions>
                  </ValidatorForm>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </DialogContent>
    </Dialog >
  )
}

VeiculoOrgaoEditModal.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedVeiculoOrgaoEditModalPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(VeiculoOrgaoEditModal))
)
export { connectedVeiculoOrgaoEditModalPage as VeiculoOrgaoEditModal }
