import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, tipoServicoAction, veiculoAction, marcaAction, subOrgaoAction, ordemServicoAction } from '../../_actions'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment'
import TitleContentInformation from '../TitleContentInformation'
import HelpIcon from '@mui/icons-material/Help';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  FormControlLabel,
  Grid,
  Paper,
  Skeleton,
  Switch,
  IconButton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { cnpjMask, stringUtils, placaMask, MANUAIS_CATEGORIAS, STATUS_SERVICO } from '../../utils'
import { withRouter } from 'react-router-dom'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Page } from 'components'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import CustomButton from '../../components/CustomButton/CustomButton'
import { HelpModal } from '../../components';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 5, 6, 5)
  },
  textField: {
    fontSize: 8
  },
  table: {
    '& .MuiTableRow-root': {
      fontSize: '0.56rem', // Aplica ao conteúdo das linhas,
      paddingLeft: 4,
      paddingBottom: 4,
      paddingTop: 4,
      paddingRight: 3
    },
    '& .MuiTableCell-root': {
      fontSize: '0.56rem', // Aplica ao conteúdo das células
      paddingLeft: 4,
      paddingBottom: 4,
      paddingTop: 4,
      paddingRight: 3
    }
  },
})

const SwitchStyled = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#000',
    }
  }
}));

const RelatorioServicoRealizado = (props) => {

  const dispatch = useDispatch()
  const isFirstRender = useRef(true);
  const { classes, className, ...rest } = props
  const { relatorio } = props.relatorio
  const { totalRegistros } = props.relatorio || 0
  const { isLoading } = props.relatorio

  const [razaoSocial, setRazaoSocial] = useState('');

  const [valueMarca, setValueMarca] = useState(null);
  const [inputValueMarca, setInputValueMarca] = useState('');

  const [cnpj, setCnpj] = useState('');
  const [emergencial, setEmergencial] = useState(false);
  const [garantia, setGarantia] = useState(false);
  const [valueTipoServico, setValueTipoServico] = useState(null);

  const [valueVeiculo, setValueVeiculo] = useState(null);
  const [inputValueVeiculo, setInputValueVeiculo] = useState('');

  const [valueSubOrgao, setValueSubOrgao] = useState(null);
  const [inputValueSubOrgao, setInputValueSubOrgao] = useState('');

  const [valueStatus, setValueStatus] = useState(null);
  const [inputValueStatus, setInputValueStatus] = useState('');

  const [inputValueTipoServico, setInputValueTipoServico] = useState('');
  const [openTipoServico, setOpenTipoServico] = useState(false)
  const [idTipoServico, setIdTipoServico] = useState(0)

  const getTodayDate = (): string => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState(getTodayDate());

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const { veiculo } = useSelector(
    (state) => state.veiculo)

  const { marca } = useSelector(
    (state) => state.marca)

  const { subOrgao } = useSelector(
    (state) => state.subOrgao)

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [ready, setReady] = React.useState(false);

  const { tipoServico } = useSelector(
    (state) => state.tipoServico)

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  useEffect(() => {
    if (idOrgao && idOrgao != 0 && idOrgao != '') {
      dispatch(veiculoAction.getVeiculoSelect(idOrgao))
      dispatch(subOrgaoAction.getSubOrgaoByIdOrgao(idOrgao))
    } else {
      dispatch(veiculoAction.clear());
      dispatch(subOrgaoAction.clear());
    }
  }, [idOrgao])

  let filterModel = {
    IdTipoServico: valueTipoServico ? valueTipoServico.id : 0,
    RazaoSocial: razaoSocial,
    Cnpj: cnpj ? cnpj.split('.').join('').replace(/[-/.]/g, '') : '',
    PlacaVeiculo: valueVeiculo && valueVeiculo.placa ? valueVeiculo.placa.split('-')[0].trim() : '',
    MarcaVeiculo: valueMarca && valueMarca.nome ? valueMarca.nome : '',
    Emergencia: emergencial,
    Garantia: garantia,
    IdOrgao: idOrgao,
    IdSubOrgao: valueSubOrgao ? valueSubOrgao.id : 0,
    Status: valueStatus ? valueStatus.id : "",
    Page: 0,
    DataInicio: dataInicio,
    DataFim: dataFim,
    Limit: 99999999
  };

  useEffect(() => {
    dispatch(tipoServicoAction.getTiposServicoSelect());
    dispatch(marcaAction.getMarcaSelect())
    dispatch(relatorioAction.clear());
  }, []);

  const optionsTiposServicos = tipoServico && tipoServico.map(u => ({ id: u.value, nome: u.text }));
  const optionsVeiculos = veiculo?.map(u => ({ id: u.value, placa: u.text }));
  const optionsSubOrgaos = subOrgao?.map(u => ({ id: u.value, nome: u.text }));
  const optionsMarcas = marca?.map(u => ({ id: u.value, nome: u.text }));
  const optionsStatus = [
    {
      id: STATUS_SERVICO.LANCAMENTO,
      nome: 'EM LANÇAMENTO'
    },
    {
      id: STATUS_SERVICO.PENDENTE,
      nome: 'PENDENTE'
    },
    {
      id: STATUS_SERVICO.RECEBENDO_ORCAMENTOS,
      nome: 'RECEBENDO ORÇAMENTOS'
    },
    {
      id: STATUS_SERVICO.ORCAMENTO_APROVADO,
      nome: 'EM EXECUÇÃO'
    },
    {
      id: STATUS_SERVICO.CONCLUIDO,
      nome: 'CONCLUÍDA'
    },
    {
      id: STATUS_SERVICO.AVALIADO,
      nome: 'AVALIADA'
    },
    {
      id: STATUS_SERVICO.VERIFICADO,
      nome: 'VERIFICADA'
    },
    {
      id: STATUS_SERVICO.RECUSADO,
      nome: 'CANCELADA'
    },
  ];

  const handleChangeAutoComplete = (obj, array, nameArray) => {
    if (obj != null) {
      let selected = array.filter(
        (item) => item.value == obj.id
      )

      if (nameArray == 'veiculo') {
        dispatch(veiculoAction.changeDetailsVeiculo(selected[0]))
      }

      if (nameArray == 'marca') {
        dispatch(marcaAction.changeDetailsMarca(selected[0]))
      }

      if (nameArray == 'subOrgao') {
        dispatch(subOrgaoAction.changeDetailsSubOrgao(selected[0]))
      }

      if (nameArray == 'status') {
        dispatch(ordemServicoAction.changeDetailsStatus(selected[0]))
      }
    } else {

    }
  }

  useEffect(() => {
    if (valueVeiculo) {
      handleChangeAutoComplete(valueVeiculo, veiculo, 'veiculo')
    }
  }, [valueVeiculo])

  useEffect(() => {
    if (valueMarca) {
      handleChangeAutoComplete(valueMarca, marca, 'marca')
    }
  }, [valueMarca])

  useEffect(() => {
    if (valueSubOrgao) {
      handleChangeAutoComplete(valueSubOrgao, subOrgao, 'subOrgao')
    }
  }, [valueSubOrgao])

  useEffect(() => {
    if (valueStatus) {
      handleChangeAutoComplete(valueStatus, optionsStatus, 'status')
    }
  }, [valueStatus])

  const handlePrint = () => {
    const queryParams = new URLSearchParams(filterModel).toString();
    if (queryParams) {
      window.open(`/relatorioServicosRealizadosPrint?${queryParams}`, '_blank');
    }
  }

  const handleExportExcel = () => {

    const modifiedFilterModel = {
      ...filterModel,
      Limit: 9999999
    };

    dispatch(relatorioAction.getRelatorioServicosRealizadosArquivo(modifiedFilterModel));
  }

  const handleSubmit = (event) => {
    dispatch(relatorioAction.getRelatorioServicosRealizados(filterModel, true));
  }

  useEffect(() => {
    // se é a primeira renderização, não faz a chamada
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    dispatch(relatorioAction.getRelatorioServicosRealizados(filterModel, true));
  }, [page, limit]);

  useEffect(() => {
    if (valueTipoServico) {
      handleChangeAutoCompleteTipoServico(valueTipoServico)
    }
  }, [valueTipoServico])

  const handleChangeAutoCompleteTipoServico = (obj) => {
    if (obj != null) {
      let selectedTípoServico = tipoServico.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        tipoServicoAction.changeDetailsTipoServico(
          selectedTípoServico[0]
        )
      )
    } else {

    }
    setOpenTipoServico(false)
  }

  const groupedRelatorio = relatorio && relatorio.length > 0 && relatorio?.reduce((acc, item) => {
    const key = item.subOrgao || "SEM SUB ÓRGÃO";
    if (!acc[key]) {
      acc[key] = { items: [], total: 0 };
    }
    acc[key].items.push(item);
    acc[key].total += item.valor || 0;
    return acc;
  }, {});

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.relatorio.isLoading])

  return (
    <Page className={classes.root} title="Relatório - Serviços Realizados">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorio"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                      <TitleContentInformation
                        subTitle={'RELATÓRIO'}
                        title={'Relatório de Serviços Realizados'}
                      />
                      <Grid item lg={1} md={1} xl={1} xs={12}>
                        <IconButton
                          aria-label="Ajuda"
                          component="a"
                          onClick={() =>
                            handleOpenModalHelp()
                          }
                          size="small"
                        >
                          <Tooltip title={'Ajuda'}>
                            <Tooltip>
                              <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                            </Tooltip>
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={5} md={5} xl={5} xs={12} style={{ marginTop: 17 }}>
                        <Autocomplete
                          value={valueTipoServico}
                          onChange={(event, newValue) => {
                            setValueTipoServico(newValue);
                          }}
                          inputValue={inputValueTipoServico}
                          onInputChange={(event, newInputValueTipoServico) => {
                            setInputValueTipoServico(newInputValueTipoServico);
                          }}
                          id="controllable-states-demo"
                          options={optionsTiposServicos}
                          margin="normal"
                          disabled={(idTipoServico != null && idTipoServico != 0 && idTipoServico != '')}
                          variant="outlined"
                          defaultValue={valueTipoServico}
                          getOptionLabel={(optionTipoServico) => (optionTipoServico ? optionTipoServico.nome : '')}
                          renderInput={(params) => <TextField {...params} label="Tipo de Serviço... " fullWidth />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="razaoSocial"
                          label="Razão Social"
                          margin="normal"
                          onInput={stringUtils.toInputUppercase}
                          onChange={(e) => setRazaoSocial(e.target.value)}
                          value={razaoSocial || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="cnpj"
                          label="CNPJ"
                          margin="normal"
                          onChange={(e) => setCnpj(e.target.value)}
                          value={cnpjMask(cnpj) || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={5} md={5} xl={5} xs={12}>
                        <Autocomplete
                          value={valueVeiculo}
                          onChange={(event, newValue) => {
                            setValueVeiculo(newValue);
                          }}
                          inputValue={inputValueVeiculo}
                          onInputChange={(event, newInputValueVeiculo) => {
                            setInputValueVeiculo(newInputValueVeiculo);
                          }}
                          id="controllable-states-demo"
                          options={optionsVeiculos}
                          margin="normal"
                          variant="outlined"
                          defaultValue={valueVeiculo}
                          getOptionLabel={(optionVeiculo) => (optionVeiculo ? optionVeiculo.placa : '')}
                          renderInput={(params) => <TextField {...params} label="Veículo... " fullWidth />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <Autocomplete
                          value={valueMarca}
                          onChange={(event, newValue) => {
                            setValueMarca(newValue);
                          }}
                          inputValue={inputValueMarca}
                          onInputChange={(event, newInputValueMarca) => {
                            setInputValueMarca(newInputValueMarca);
                          }}
                          id="controllable-states-demo"
                          options={optionsMarcas}
                          margin="normal"
                          variant="outlined"
                          defaultValue={valueMarca}
                          getOptionLabel={(optionMarca) => (optionMarca ? optionMarca.nome : '')}
                          renderInput={(params) => <TextField {...params} label="Marca do Veículo " fullWidth />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: 5, paddingBottom: 10 }}>
                        <FormControlLabel
                          control={
                            <SwitchStyled
                              color="default"
                              checked={emergencial}
                              onChange={() => setEmergencial(!emergencial)}
                            />
                          }
                          label="Apenas Serviços Emergenciais"
                        />
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="data"
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          label="Data Início"
                          margin="normal"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          onChange={(e) => setDataInicio(e.target.value)}
                          value={dataInicio || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="data"
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          label="Data Fim"
                          margin="normal"
                          onChange={(e) => setDataFim(e.target.value)}
                          value={dataFim || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12} style={{ marginTop: 17 }}>
                        <Autocomplete
                          value={valueSubOrgao}
                          onChange={(event, newValue) => {
                            setValueSubOrgao(newValue);
                          }}
                          inputValue={inputValueSubOrgao}
                          onInputChange={(event, newInputValueSubOrgao) => {
                            setInputValueSubOrgao(newInputValueSubOrgao);
                          }}
                          id="controllable-states-demo"
                          options={optionsSubOrgaos}
                          margin="normal"
                          variant="outlined"
                          defaultValue={valueSubOrgao}
                          getOptionLabel={(optionOrgao) => (optionOrgao ? optionOrgao.nome : '')}
                          renderInput={(params) => <TextField {...params} label="Sub Órgão" fullWidth />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: 5, paddingBottom: 10 }}>
                        <FormControlLabel
                          control={
                            <SwitchStyled
                              color="default"
                              checked={garantia}
                              onChange={() => setGarantia(!garantia)}
                            />
                          }
                          label="Apenas Serviços Com Garantia"
                        />
                      </Grid>
                      <Grid item lg={5} md={5} xl={5} xs={12} style={{ marginTop: 17 }}>
                        <Autocomplete
                          value={valueStatus}
                          onChange={(event, newValue) => {
                            setValueStatus(newValue);
                          }}
                          inputValue={inputValueStatus}
                          onInputChange={(event, newInputValueStatus) => {
                            setInputValueStatus(newInputValueStatus);
                          }}
                          id="controllable-states-demo"
                          options={optionsStatus}
                          margin="normal"
                          variant="outlined"
                          defaultValue={valueStatus}
                          getOptionLabel={(optionOrgao) => (optionOrgao ? optionOrgao.nome : '')}
                          renderInput={(params) => <TextField {...params} label="Status da O.S" fullWidth />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Typography style={{ fontSize: "11px", paddingLeft: "6px" }}>
                          * Os campos de data filtram a data de início da ordem de serviço
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                      <Grid item xl={12} lg={12} md={12} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <CustomButton
                            type="submit"
                            variant="contained"
                            dark={true}
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="submit"
                            onClick={() => handlePrint()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFilePdf} style={{ verticalAlign: 'middle' }} />}
                          >
                            Gerar PDF
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="button"
                            onClick={() => handleExportExcel()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFileExcel} style={{ verticalAlign: 'middle' }} />}
                          >
                            Exportar para Excel
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>DATA</strong></TableCell>
                        <TableCell><strong>SERVIÇO REALIZADO</strong></TableCell>
                        <TableCell><strong>TIPO DE SERVIÇO</strong></TableCell>
                        <TableCell><strong>RAZÃO SOCIAL</strong></TableCell>
                        <TableCell><strong>VEÍCULO</strong></TableCell>
                        <TableCell><strong>MARCA</strong></TableCell>
                        <TableCell><strong>STATUS</strong></TableCell>
                        <TableCell align="right"><strong>VALOR</strong></TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {relatorio && relatorio.length > 0 ? Object.keys(groupedRelatorio).map((group) => (
                        <React.Fragment key={group}>
                          {/* Linha de cabeçalho para o grupo */}
                          <TableRow>
                            <TableCell colSpan={7} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                              {group}
                            </TableCell>
                            <TableCell align="right" style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                              VALOR TOTAL DO SUB ÓRGÃO: {`R$ ${groupedRelatorio[group].total.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}
                            </TableCell>
                            <TableCell style={{ backgroundColor: '#f5f5f5' }}></TableCell>
                          </TableRow>
                          {/* Linhas de dados do grupo */}
                          {groupedRelatorio[group].items.map((n) => (
                            <>
                              <TableRow hover key={n.id}>
                                <TableCell>{n.dataOrdemServico ? moment(n.dataOrdemServico).format("DD/MM/yyyy") : '-'}</TableCell>
                                <TableCell>
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {n.emergencia && (
                                      <Tooltip title="Ordem de serviço emergencial">
                                        <WarningAmberIcon sx={{ color: '#ef5350', fontSize: '1.5em', marginRight: 0.5 }} />
                                      </Tooltip>
                                    )}
                                    {n.garantia ? (
                                      <Tooltip title="Exige garantia">
                                        <WorkspacePremiumOutlinedIcon sx={{ fontSize: '1.5em', marginRight: 0.5, verticalAlign: 'middle' }} />
                                      </Tooltip>
                                    ) : null}
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <div>#{n.idOrdemServico} {n.tituloOrdemServico?.toUpperCase()}</div>
                                      {n.descritivoOrdemServico && (
                                        <div dangerouslySetInnerHTML={{ __html: stringUtils.sanitizeHTML(n.descritivoOrdemServico.toUpperCase()) }} />
                                      )}
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>{n.tipoServico?.toUpperCase() || '-'}</TableCell>
                                <TableCell>{n.nomeEmpresa?.toUpperCase() || ''}</TableCell>
                                <TableCell>
                                  {n.placaVeiculo ? placaMask(n.placaVeiculo) : '-'}
                                </TableCell>
                                <TableCell>{n.marcaVeiculo?.toUpperCase() || '-'}</TableCell>
                                <TableCell>
                                  {n.status ? n.status : '-'}
                                </TableCell>
                                <TableCell align="right">{n.valor ? `R$${n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : '-'}</TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    aria-label="Visualizar Ordem de Serviço"
                                    component="a"
                                    href={`/ordemServico/${n.idOrdemServico}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    size="small"
                                  >
                                    <Tooltip title="Visualizar Ordem de Serviço">
                                      <VisibilityIcon sx={{ fontSize: '1em' }} />
                                    </Tooltip>
                                  </IconButton>
                                </TableCell>
                              </TableRow >
                              {
                                n.garantia && n.ordemServicoOrcamentoAprovado && n.ordemServicoOrcamentoAprovado.observacoesGarantia != null &&
                                  n.ordemServicoOrcamentoAprovado.observacoesGarantia != "" ?
                                  <TableRow hover key={`garantia` + n.id}>
                                    <TableCell colSpan={9}>
                                      <strong>DETALHES DA GARANTIA: </strong>{n.ordemServicoOrcamentoAprovado.observacoesGarantia.toUpperCase()}
                                    </TableCell>
                                  </TableRow >
                                  : null
                              }
                            </>
                          ))}
                        </React.Fragment>
                      ))
                        :
                        (isLoading ?
                          Array.apply(null, { length: 6 }).map((e, i) => (
                            <TableRow hover>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                            </TableRow>
                          ))
                          :
                          <TableRow>
                            <TableCell rowSpan={6} colSpan={8} align="center">Nenhum registro encontrado</TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            {
              (modalHelp ?
                <HelpModal
                  toggle={toggleModalHelp}
                  modal={modalHelp}
                  props={props}
                  tela={MANUAIS_CATEGORIAS.RELATORIO_SERVICO_REALIZADO}
                />
                :
                null
              )
            }
          </Grid>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
RelatorioServicoRealizado.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    relatorio: state.relatorio
  }
}
const connectedRelatorioServicoRealizadoPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(RelatorioServicoRealizado))
)
export { connectedRelatorioServicoRealizadoPage as RelatorioServicoRealizado }
