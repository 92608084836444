import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  Button,
  Card,
  Chip,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  FormLabel
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import {
  empresaAction,
  orgaoAction,
  cidadeAction,
  estadoAction,
  parametroAction,
  usuarioAction,
  tipoServicoAction,
  empresaOrgaoAction,
  tipoVeiculoAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, cnpjMask, stringUtils, phoneMask, cpfMask, TIPO_USUARIO } from '../../utils'
import { Page } from 'components'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../img/rotorSemFundo.png'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import CustomButton from '../../components/CustomButton/CustomButton'
import { maxWidth } from '@mui/system'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left'
  },
}));

const SwitchBlue = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#000000',
    }
  }
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: -10,
    marginBottom: -3,
    paddingTop: -10,
    paddingBottom: -8,
    paddingRight: 3,
    marginRight: 3,
    paddingLeft: 3,
    marginLeft: 3
  },
}));

const CustomTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 'bold',
  textAlign: "left",
  marginRight: 5,
  [theme.breakpoints.down('sm')]: {
    fontSize: "15px",
    width: '85%',
    fontWeight: 'bold',
    textAlign: "left",
  },
}));

const CustomButtonModal = styled(Button)(({ theme }) => ({
  backgroundColor: '#000',
  position: 'relative',
  marginTop: 15,
  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: 10,
    marginRight: 10,
    minWidth: 45,
    maxWidth: 50,
    fontSize: 12,
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
}));

const CustomIframe = styled('iframe')(({ theme }) => ({
  width: '100%',
  height: 450,
  objectFit: 'contain',
  padding: 3,
  justifycontent: 'right',
  borderRadius: 7,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 450,
    objectFit: 'contain',
    padding: 3,
    justifycontent: 'center',
    borderRadius: 7,
  },
}));


const EmpresaUsuarioEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, empresa, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const location = useLocation()
  const isAutonomo = location?.search?.includes('type=autonomo') || props.empresa?.autonomo
  const isPrestador = location?.search?.includes('type=prestador') || props.empresa?.prestadorServico
  const isFornecedor = location?.search?.includes('type=fornecedor') || props.empresa?.fornecedor

  const [aceiteTermos, setAceiteTermos] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [valueEstado, setValueEstado] = React.useState(null);
  const [inputValueEstado, setInputValueEstado] = React.useState('');

  const [selectedTiposVeiculos, setSelectedTiposVeiculos] = useState([]);
  const [selectedTiposServicos, setSelectedTiposServicos] = useState([]);
  const [selectedEmpresaOrgaos, setSelectedEmpresaOrgaos] = useState([]);

  const tiposVeiculo = useSelector((state) => state.tipoVeiculo.tipoVeiculo)

  const { cidade } = useSelector(
    (state) => state.cidade
  )

  const { estado } = useSelector(
    (state) => state.estado
  )

  const tiposServico = useSelector((state) => state.tipoServico.tipoServico)
  const empresasOrgaos = useSelector((state) => state.orgao.orgao)
  const optionsEstado = estado.map(u => ({ id: u.value, nome: u.text }));

  const options = cidade.map(u => ({ id: u.value, nome: u.text }));

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);
  const [idOrgaoTeste, setIdOrgaoTeste] = React.useState(0);

  useEffect(() => {

    dispatch(orgaoAction.getOrgaoSelect())
    dispatch(empresaAction.clearUsuariosEmpresas())
    dispatch(tipoVeiculoAction.getTiposVeiculo())
    dispatch(estadoAction.getEstadoSelect())
    dispatch(tipoServicoAction.getTiposServico())
    dispatch(empresaOrgaoAction.getEmpresaOrgaos())

    dispatch(parametroAction.getTermoAceiteEmpresas())

    if (params.id) {
      dispatch(empresaAction.getEmpresaById(params.id))
      setSelectedEmpresaOrgaos(props.empresa.empresasOrgaos)
    } else {
      dispatch(empresaAction.clear())
      dispatch(empresaAction.clearTiposVeiculos())
      dispatch(usuarioAction.clear())
      dispatch(empresaAction.clearUsuariosEmpresas())
    }

    if (props.empresa.idEstado != undefined && props.empresa.idEstado !== 0 && props.empresa.idEstado !== '') {
      dispatch(cidadeAction.getCidadeByIdEstado(props.empresa.idEstado))
    }

    const interval = setInterval(() => {
      setReady(true)
    }, 1500);
    return () => clearInterval(interval);

  }, []);
  async function fetchIdOrgaoTeste() {
    setIdOrgaoTeste(await dispatch(parametroAction.getIdOrgaoTeste()));
  }

  useEffect(() => {
    fetchIdOrgaoTeste();
  }, [idOrgaoTeste]);

  useEffect(() => {
    if (props.empresa.idCidade != 0 && params.id != undefined) {
      setValue(props.empresa.cidade)
    }
  }, [props.empresa.idCidade])

  useEffect(() => {
    if (valueEstado) {
      handleChangeAutoComplete(valueEstado, estado, 'estado')
    }
  }, [valueEstado])

  function handleChangeTipoVeiculo(id) {
    const isSelected = selectedTiposVeiculos?.some((n) => n.idTipoVeiculo == id)
    if (isSelected) {
      const newSelected = selectedTiposVeiculos?.filter((n) => n.idTipoVeiculo != id)
      setSelectedTiposVeiculos(newSelected)
    } else {
      let newSelecteds = {
        id: 0,
        idTipoVeiculo: id,
        idEmpresa: props.empresa.id
      }
      setSelectedTiposVeiculos([...selectedTiposVeiculos, newSelecteds])
    }
  }

  function handleChangeTipoServico(id) {
    const isSelected = selectedTiposServicos?.some((n) => n.idTipoServico == id)
    if (isSelected) {
      const newSelected = selectedTiposServicos?.filter((n) => n.idTipoServico != id)
      setSelectedTiposServicos(newSelected)
    } else {
      let newSelecteds = {
        id: 0,
        idTipoServico: id,
        idEmpresa: props.empresa.id
      }
      setSelectedTiposServicos([...selectedTiposServicos, newSelecteds])
    }
  }

  function handleChangeEmpresaOrgaos(id) {
    const isSelected = selectedEmpresaOrgaos?.some((o) => o.idOrgao == id)
    if (isSelected) {
      const newSelected = selectedEmpresaOrgaos?.filter((o) => o.idOrgao != id)
      setSelectedEmpresaOrgaos(newSelected)
    } else {
      let newSelecteds = {
        id: 0,
        idOrgao: id,
        idEmpresa: props.empresa.id
      }
      setSelectedEmpresaOrgaos([...selectedEmpresaOrgaos, newSelecteds])
    }
  }

  const isTipoServicoSelected = (id) => {
    if (id != undefined) {
      return (props.empresa?.empresasTiposServicos?.some((n) => n.idTipoServico == id) || selectedTiposServicos?.some((n) => n.idTipoServico == id))
    }
  }
  const isEmpresaOrgaosSelected = (id) => {
    if (id != undefined) {
      return (props.empresa?.empresasOrgaos?.some((o) => o.idOrgao == id) || selectedEmpresaOrgaos?.some((o) => o.id == id))
    }
  }

  useEffect(() => {
    if (valueEstado && valueEstado.id) {
      dispatch(cidadeAction.getCidadeByIdEstado(valueEstado.id))
    }
  }, [valueEstado?.id])

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value, cidade, 'cidade')
    }
  }, [value])

  useEffect(() => {
    if (valueEstado) {
      handleChangeAutoComplete(valueEstado, estado, 'estado')
    }
  }, [valueEstado])

  const handleChangeAutoComplete = (obj, array, nameArray) => {
    if (obj != null) {
      let selected = array.filter(
        (item) => item.value == obj.id
      )

      if (nameArray == 'estado') {
        dispatch(estadoAction.changeDetailsEstado(selected[0]))
      } else if (nameArray == 'cidade') {
        dispatch(cidadeAction.changeDetailsCidade(selected[0]))
      }

    } else {

    }
  }

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.empresa.usuariosEmpresas])

  useEffect(() => {

  }, [ready, props.empresa.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(empresaAction.onChangeProps(prop, event))
  }

  const handleChangeUsuario = (prop) => (event) => {
    dispatch(usuarioAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    props.empresa.empresasTiposServicos = selectedTiposServicos;
    forceUpdate(n => !n);
  }, [selectedTiposServicos])

  useEffect(() => {
    props.empresa.empresasOrgaos = selectedEmpresaOrgaos;
    forceUpdate(n => !n);
  }, [selectedEmpresaOrgaos])

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.empresa.empresasTiposServicos])

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.empresa.empresasOrgaos])

  const handleSubmit = (event) => {

    let payload = {
      razaoSocial: isAutonomo ? `${props.usuario.nome} ${props.usuario.sobrenome}` : props.empresa.razaoSocial,
      nomeFantasia: isAutonomo ? `${props.usuario.nome} ${props.usuario.sobrenome}` : props.empresa.nomeFantasia,
      cnpj: props.empresa.cnpj ? props.empresa.cnpj.split('.').join('').replace(/[-/.]/g, '') : null,
      cpf: props.empresa.cpf ? props.empresa.cpf.split('.').join('').replace(/[-/.]/g, '') : null,
      celular: isAutonomo ? props.usuario.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', '') : props.empresa.celular ? props.empresa.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', '') : '',
      email: isAutonomo ? props.usuario.email.toLowerCase() : props.empresa.email ? props.empresa.email.toLowerCase() : '',
      telefone: isAutonomo ? props.usuario.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', '') : props.empresa.telefone ? props.empresa.telefone.split('-').join('').replace('(', '').replace(')', '').replace(' ', '') : '',
      endereco: props.empresa.endereco,
      inscricaoEstadual: props.empresa.inscricaoEstadual,
      idCidade: value ? value.id : props.empresa.idCidade,
      idEstado: valueEstado ? valueEstado.id : 0,
      usuariosEmpresas: props.empresa.usuariosEmpresas,
      empresasOrgaos: props.empresa.empresasOrgaos,
      fornecedor: isFornecedor,
      autonomo: isAutonomo,
      prestadorServico: isPrestador,
      aceiteTermos: aceiteTermos,
      atendeFimDeSemana: props.empresa.atendeFimDeSemana,
      servicosUrgencia: props.empresa.servicosUrgencia,
      empresasTiposServicos: props.empresa.empresasTiposServicos,
      empresasTiposVeiculos: props.empresa.empresasTiposVeiculos,
      usuariosEmpresas: [
        {
          id: 0,
          idUsuario: 0,
          idEmpresa: 0,
          usuario:
          {
            nome: props.usuario.nome,
            senha: props.usuario.senha,
            sobrenome: props.usuario.sobrenome,
            email: props.usuario.email.toLowerCase(),
            idTipoUsuario: TIPO_USUARIO.PRESTADOR,
            celular: props.usuario.celular && props.usuario.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
            matricula: props.usuario.matricula,
            telefone: props.usuario.telefone && props.usuario.telefone.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
          }
        }
      ]
    }

    if (props.empresa.empresasTiposServicos.length > 0) {
      if (props.empresa.empresasTiposVeiculos.length > 0) {
        dispatch(empresaAction.createEmpresaApp(payload))
      } else {
        toast.error('Selecione ao menos um tipo de veículo')
      }
    } else {
      toast.error('Selecione ao menos um tipo de serviço')
    }
  };

  useEffect(() => {
    props.empresa.empresasTiposVeiculos = selectedTiposVeiculos;
    forceUpdate(n => !n);
  }, [selectedTiposVeiculos])

  const isTipoVeiculoSelected = (id) => {
    if (id != undefined) {
      return (props.empresa?.empresasTiposVeiculos?.some((n) => n.idTipoVeiculo == id) || selectedTiposVeiculos?.some((n) => n.idTipoVeiculo == id))
    }
  }

  const handleModalConfirm = (event) => {
    setModalConfirm(true);
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{isAutonomo ? 'Cadastro de Profissional Autônomo' : isFornecedor ? "Cadastro de Fornecedor de Materiais" : 'Cadastro de Prestador de Serviços'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={'Gerência de Empresas - Nova Empresa'}
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formEmpresa"
                onSubmit={(event) => handleModalConfirm(event)}
              >
                <img
                  width={140}
                  alt="Logo Rotor"
                  src={logo}
                  align='right'
                  style={{ paddingTop: 20, paddingRight: 20 }}
                />
                <CardHeader
                  subheader={isAutonomo ? "Dados do Profissional" : isFornecedor ? "Dados do Fornecedor" : "Dados do Prestador de Serviço"}
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12} style={isAutonomo ? { paddingBottom: 0 } : {}}>
                  <Grid container spacing={2}>
                    {!isAutonomo &&
                      <>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="razaoSocial"
                            label="Razão Social *"
                            margin="normal"
                            onInput={stringUtils.toInputUppercase}
                            onChange={handleChange('razaoSocial')}
                            value={props.empresa.razaoSocial || ''}
                            variant="outlined"
                            validators={!isAutonomo && ['required']}
                            errorMessages={['Campo obrigatório!']}

                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="nomeFantasia"
                            label="Nome Fantasia *"
                            margin="normal"
                            onInput={stringUtils.toInputUppercase}
                            onChange={handleChange('nomeFantasia')}
                            value={props.empresa.nomeFantasia || ''}
                            variant="outlined"
                            validators={!isAutonomo && ['required']}
                            errorMessages={['Campo obrigatório!']}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="cnpj"
                            label={"CNPJ *"}
                            margin="normal"
                            onChange={handleChange('cnpj')}
                            value={cnpjMask(props.empresa.cnpj) || ''}
                            variant="outlined"
                            validators={isAutonomo ? [] : ['required']}
                            errorMessages={['Campo obrigatório!']}
                          />
                        </Grid>
                      </>
                    }
                    {isAutonomo &&
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="cpf"
                          label={"CPF *"}
                          margin="normal"
                          onChange={handleChange('cpf')}
                          value={cpfMask(props.empresa.cpf) || ''}
                          variant="outlined"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                    }
                    {!isAutonomo &&
                      <>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            type="number"
                            id="ie"
                            inputProps={{ maxLength: 12 }}
                            label="Inscrição Estadual (Apenas números)"
                            margin="normal"
                            onChange={handleChange('inscricaoEstadual')}
                            value={props.empresa.inscricaoEstadual || ''}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    }
                    {!isAutonomo &&
                      <>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="E-mail *"
                            onInput={stringUtils.toInputLowerCase}
                            value={props.empresa.email || ''}
                            className={classes.textField}
                            onChange={handleChange('email')}
                            id="email"
                            name="email"
                            autoComplete="email"
                            validators={!isAutonomo && ['required', 'isEmail']}
                            errorMessages={[
                              'Você precisa informar o e-mail',
                              'E-mail inválido'
                            ]}
                            disabled={props.empresa.id != 0}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="telefone"
                            label="Telefone *"
                            margin="normal"
                            onChange={handleChange('telefone')}
                            value={phoneMask(props.empresa.telefone) || ''}
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="celular"
                            label="Celular *"
                            margin="normal"
                            onChange={handleChange('celular')}
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            value={phoneMask(props.empresa.celular) || ''}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    }
                    <Grid item lg={2} md={2} xl={2} xs={12} style={{ marginTop: 16 }}>
                      <Autocomplete
                        value={valueEstado}
                        onChange={(event, newValueEstado) => {
                          setValueEstado(newValueEstado);
                        }}
                        inputValue={inputValueEstado}
                        onInputChange={(event, newInputValueEstado) => {
                          setInputValueEstado(newInputValueEstado);
                        }}
                        id="controllable-states-demo"
                        options={optionsEstado}
                        margin="normal"
                        variant="outlined"
                        defaultValue={valueEstado}
                        isOptionEqualToValue={(option, value) => option.id == value.id}
                        getOptionLabel={option => option.nome}
                        renderInput={(params) => <TextField required {...params} label="Selecione um estado" fullWidth />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ paddingTop: 31 }}>
                      <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={options}
                        margin="normal"
                        variant="outlined"
                        defaultValue={value}
                        getOptionLabel={option => option.nome || ''}
                        renderInput={(params) => <TextField {...params} required label="Selecione uma cidade..." fullWidth />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="endereco"
                        label="Endereço *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('endereco')}
                        value={props.empresa.endereco || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <CardContent style={isAutonomo ? { paddingVertical: 0, placeItems: 'center' } : { placeItems: 'center' }}>
                        {(isPrestador || isFornecedor) &&
                          <>
                            <FormControlLabel
                              control={
                                <SwitchBlue
                                  color="default"
                                  checked={isFornecedor}
                                  onChange={handleChange('fornecedor')}

                                />
                              }
                              label="Fornecedor"
                            />
                            <FormControlLabel
                              control={
                                <SwitchBlue
                                  color="default"
                                  checked={isPrestador}
                                  onChange={handleChange('prestadorServico')}
                                />
                              }
                              label="Prestador de Serviço"
                            />
                          </>
                        }
                        <FormControlLabel
                          control={
                            <SwitchBlue
                              color="default"
                              checked={props.empresa.atendeFimDeSemana}
                              onChange={handleChange('atendeFimDeSemana')}
                            />
                          }
                          label="Atende em Finais de Semana"
                        />
                        <FormControlLabel
                          control={
                            <SwitchBlue
                              color="default"
                              checked={props.empresa.servicosUrgencia}
                              onChange={handleChange('servicosUrgencia')}
                            />
                          }
                          label="Realiza Serviços de Urgência"
                        />
                      </CardContent>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography component="h4" variant="subtitle" align="center">
                        Tipos de veículos atendidos pela empresa *
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12} align="center">
                      {tiposVeiculo && tiposVeiculo != undefined && tiposVeiculo.length > 0 &&
                        tiposVeiculo.map((n) => (
                          <Chip label={n.nome} onClick={() => handleChangeTipoVeiculo(n.id)}
                            variant={isTipoVeiculoSelected(n.id) ? "filled" : "outlined"} style={{ backgroundColor: isTipoVeiculoSelected(n.id) && '#F2D647', color: isTipoVeiculoSelected(n.id) && '#000000', margin: 5 }} />
                        ))
                      }
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography component="h4" variant="subtitle" align="center">
                        Tipos de serviços oferecidos pela empresa *
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12} align="center">
                      {tiposServico && tiposServico != undefined && tiposServico.length > 0 &&
                        tiposServico.map((n, index) => (
                          <Chip label={n.nome} key={index} onClick={() => handleChangeTipoServico(n.id)}
                            variant={isTipoServicoSelected(n.id) ? "filled" : "outlined"} style={{ backgroundColor: isTipoServicoSelected(n.id) && '#F2D647', color: isTipoServicoSelected(n.id) && '#000000', margin: 5 }} />
                        ))
                      }
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography component="h4" variant="subtitle" align="center">
                        Órgãos de interesse *
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12} align="center">
                      {empresasOrgaos && empresasOrgaos != undefined && empresasOrgaos.length > 0 &&
                        empresasOrgaos.map((o, index) => {
                          return (
                            o.value != idOrgaoTeste &&
                            <Chip label={o.text} key={index} onClick={() => handleChangeEmpresaOrgaos(o.value)}
                              variant={isEmpresaOrgaosSelected(o.value) ? "filled" : "outlined"} style={{ backgroundColor: isEmpresaOrgaosSelected(o.value) && '#F2D647', color: isEmpresaOrgaosSelected(o.value) && '#000000', margin: 5 }} />
                          )
                        }
                        )
                      }
                    </Grid>
                  </Grid>
                </CardContent>
                {!isAutonomo &&
                  <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                    <Divider />
                  </Grid>
                }
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <Card>
                    {!isAutonomo &&
                      <>
                        <CardHeader
                          subheader="Dados do Usuário Gestor"
                          title={<Typography variant="h5"><b>Cadastro de Usuário</b></Typography>}
                        />
                        <Divider />
                      </>
                    }
                    <CardContent lg={12} md={12} xl={12} xs={12} style={isAutonomo ? { paddingTop: 0 } : {}}>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="nome"
                            label="Nome *"
                            margin="normal"
                            onInput={stringUtils.toInputUppercase}
                            onChange={handleChangeUsuario('nome')}
                            value={props.usuario.nome || ''}
                            variant="outlined"
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="sobrenome"
                            label="Sobrenome *"
                            margin="normal"
                            onInput={stringUtils.toInputUppercase}
                            onChange={handleChangeUsuario('sobrenome')}
                            value={props.usuario.sobrenome || ''}
                            variant="outlined"
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="E-mail *"
                            value={props.usuario.email || ''}
                            className={classes.textField}
                            onInput={stringUtils.toInputLowerCase}
                            onChange={handleChangeUsuario('email')}
                            id="email"
                            name="email"
                            autoComplete="email"
                            validators={['required', 'isEmail']}
                            errorMessages={[
                              'Você precisa informar o e-mail',
                              'E-mail inválido'
                            ]}
                            disabled={props.usuario.id != 0}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="celular"
                            label={isAutonomo ? "Celular *" : "Celular "}
                            margin="normal"
                            onChange={handleChangeUsuario('celular')}
                            value={phoneMask(props.usuario.celular) || ''}
                            validators={isAutonomo ? ['required'] : []}
                            errorMessages={['Campo obrigatório!']}
                            variant="outlined"
                          />
                        </Grid>
                        {!isAutonomo &&
                          <>
                            <Grid item lg={6} md={6} xl={6} xs={12}>
                              <TextValidator
                                className={classes.textField}
                                fullWidth
                                id="telefone"
                                label="Telefone "
                                margin="normal"
                                onChange={handleChangeUsuario('telefone')}
                                value={phoneMask(props.usuario.telefone) || ''}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item lg={6} md={6} xl={6} xs={12}>
                              <TextValidator
                                className={classes.textField}
                                fullWidth
                                id="matricula"
                                label="Matrícula"
                                type="number"
                                margin="normal"
                                onChange={handleChangeUsuario('matricula')}
                                value={props.usuario.matricula || ''}
                                variant="outlined"
                              />
                            </Grid>
                          </>
                        }
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                          * Campos obrigatórios
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} style={{
                        display: 'flex',
                        alignItems: 'right',
                        flexDirection: 'row',
                        textAlgin: 'right',
                        justifyContent: 'right'
                      }}>
                        <CustomButton
                          variant="outlined"
                          onClick={() => history.push('/login')}
                        >
                          Cancelar
                        </CustomButton>
                        <CustomButton
                          type="submit"
                          dark={true}
                          variant="contained"
                          disabled={props.empresa.isLoading}
                          startIcon={<SaveIcon />}
                          endIcon={props.empresa.isLoading && <CircularProgress size={24} />}
                        >
                          {props.empresa.isLoading ? 'Salvando empresa...' : 'Salvar'}
                        </CustomButton>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {modalConfirm ?
        <Dialog
          open={modalConfirm}
          fullWidth
          style={{ padding: 0, margin: 0 }}
          maxWidth='md'
          onClose={() => setModalConfirm(false)}
        >
          <DialogTitle>
            <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
              <Grid item>
                <CustomTypographyTitle>
                  CONFIRMAÇÃO DE ACEITE DOS TERMOS DE USO
                </CustomTypographyTitle>
              </Grid>
              <CustomButtonModal
                variant="contained"
                size="small"
                onClick={() => setModalConfirm(false)}>
                <CloseIcon />
              </CustomButtonModal>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ padding: 0, margin: 0 }}>
            <Card style={{ marginTop: 0, paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}>
              <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                  <CustomTypography style={{ fontSize: 16, marginTop: 8 }} ><strong>Ao se cadastrar, você confirma que está de acordo com o Termo a seguir:</strong></CustomTypography>
                </CustomGrid>
              </Grid>
              <Divider />
              <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                  {
                    props.parametro.valor ?
                      <CustomIframe
                        src={props.parametro.valor}
                        frameBorder="0"
                        scrolling="yes"
                      >
                      </CustomIframe>
                      : 'Sem contrato'
                  }
                </CustomGrid>
              </Grid>
              <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                <Grid item lg={12} md={12} xl={12} xs={12} >
                  <FormControlLabel
                    control={
                      <SwitchBlue
                        checked={aceiteTermos}
                        onChange={() => setAceiteTermos(!aceiteTermos)}
                      />
                    }
                    label="Eu li e aceito os termos presentes no Contrato listado acima"
                  />
                </Grid>
              </Grid>
              {aceiteTermos &&
                <CustomButton
                  onClick={(event) => handleSubmit(event)}
                  variant="contained"
                  dark={true}
                  startIcon={<SaveIcon />}
                  endIcon={props.empresa.isLoading && <CircularProgress size={24} />}
                >
                  {params.id ? (props.empresa.isLoading ? 'Salvando...' : 'Salvar') : (props.empresa.isLoading ? 'Salvando...' : 'Salvar')}
                </CustomButton>
              }
            </Card>
          </DialogContent>
        </Dialog >
        : null
      }
    </Page >
  )
}

const mapStateToProps = (state) => {
  return state
}

const connectedEmpresaUsuarioEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(EmpresaUsuarioEdit))
)
export { connectedEmpresaUsuarioEditPage as EmpresaUsuarioEdit }
