import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save';
import { uploadFile } from '_services'
import { useConfirm } from 'material-ui-confirm'
import { toast } from 'react-toastify'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip
} from '@mui/material'
import { styled } from '@mui/material/styles';
import {
  ordemServicoAction,
} from '../../_actions'
import {
  ValidatorForm
} from 'react-material-ui-form-validator'

const CustomInput = styled("input")`
  display: none;
`;

const CustomTypography = styled(Typography)(() => ({
  marginTop: 4,
  alignItems: 'center',
  color: '#666666',
  marginRight: 20,
  fontSize: 11
}));

const CustomDeleteIcon = styled(DeleteIcon)(() => ({
  color: '#4f4f4f',
  marginTop: 47,
  fontSize: 21,
  '&:hover': {
    cursor: 'pointer'
  },
}));

const SwitchStyled = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#000',
    }
  }
}));

export const OrdemServicoAnexoModal = ({ modal, toggle, props, ordemServico }) => {

  const dispatch = useDispatch()
  const currentDate = moment().format("yyyy-MM-DD");
  const confirm = useConfirm()

  const [filesUploaded, setFilesUploaded] = useState([]);
  const [openFile, setOpenFile] = useState(false);
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const [visivelParaPrestador, setVisivelPrestador] = useState(false);

  const user = useSelector((state) => state.authentication.user)

  useEffect(() => {
    dispatch(ordemServicoAction.clearAnexos());
  }, [])

  const handleClose = () => {
    toggle();
  }

  useEffect(() => {
    console.log('ordemServicooooo', ordemServico)
  }, [ordemServico])

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>Enviar Anexos</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  const isValidAnexos = () => {
    if (visivelParaPrestador && (!filesUploaded || filesUploaded.length <= 0)) {
      toast.error('É necessário fazer upload de pelo menos um arquivo para prosseguir')
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = (event) => {
    // Crie o payload diretamente como a estrutura correta esperada pelo backend
    let payload = {
      ...ordemServico, // Desestrutura diretamente os dados da ordem de serviço
      ordensServicosAnexos: filesUploaded.map(file => ({
        ...file,
        idOrdemServico: ordemServico?.id, // Atribui o id da ordem de serviço aos anexos
        visivelParaPrestador: visivelParaPrestador // Define a visibilidade para o prestador
      }))
    };

    console.log("Payload enviado:", payload); // Imprime para debug
    if (isValidAnexos()) {
      dispatch(ordemServicoAction.editOrdemServicoInfo(payload, ordemServico.id));
      toggle();
    }
  };

  const handleUploadClickFiles = (event) => {
    const files = Array.from(event.target.files); // Transformando em array para iteração

    setOpenFile(true);

    // Array para armazenar as promessas de upload
    const uploadPromises = files.map((file) =>
      uploadFile(file)
        .then((response) => {
          return { caminhoAnexo: response.toString() };
        })
        .catch((err) => {
          console.log(err);
          return null;
        })
    );

    // Esperar que todos os uploads sejam concluídos
    Promise.all(uploadPromises)
      .then((uploadedFiles) => {
        // Filtrar arquivos que tiveram sucesso no upload (não são null)
        const successfulUploads = uploadedFiles.filter((file) => file !== null);
        setFilesUploaded([...filesUploaded, ...successfulUploads]);
        setOpenFile(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenFile(false);
      });
  };

  const handleRemoveOnListFile = caminhoAnexo => {
    confirm({
      title: 'Você deseja excluir este arquivo?',
      description: 'Essa operação removerá o arquivo desta ordem de serviço',
      disabled: props.ordemServico.isLoading,
      confirmationText: props.ordemServico.isLoading ? 'Excluindo arquivo...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      setFilesUploaded(filesUploaded.filter(ts => ts.caminhoAnexo != caminhoAnexo));
    })
  };

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='lg'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Cadastros"
              title={<SegHeader />}
            />
          </div>
          <Divider />
          <CardContent>
            <ValidatorForm
              className={classes.form}
              ref={formRef}
              id="formDiarioObra"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={2}>
                <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid item lg={3} md={3} xl={3} xs={12} align="center" style={{ marginTop: 10 }}>
                    <FormControlLabel
                      control={
                        <SwitchStyled
                          color="default"
                          checked={visivelParaPrestador}
                          onChange={(e) => setVisivelPrestador(e.target.checked)}
                        />
                      }
                      label="Visível para prestador"
                    />
                  </Grid>
                  <Grid item lg={3} md={3} xl={3} xs={12} align="center" style={{ marginTop: 10 }}>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openFile}>
                      <Grid
                        container
                        spacing={3}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                      >
                        <Grid item>
                          <CircularProgress color="primary" />
                        </Grid>
                        <Grid item>
                          <Typography variant="h6" color="textPrimary">
                            Enviando arquivo(s)...
                          </Typography>
                        </Grid>
                      </Grid>
                    </Backdrop>
                    <CustomTypography variant="body2" align="center">
                      Enviar arquivos
                    </CustomTypography>
                    <CustomInput
                      accept="files/*"
                      id="file"
                      type="file"
                      multiple
                      onChange={(e) => handleUploadClickFiles(e)}
                    />
                    <label htmlFor="file">
                      <Tooltip title="Enviar arquivos">
                        <Fab component="span" style={{ justifyContent: 'center' }}>
                          <AttachFileIcon />
                        </Fab>
                      </Tooltip>
                    </label>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12} aSwitchStyledlign="left">
                    {filesUploaded && filesUploaded.length > 0 &&
                      <Table size="small" fullWidth>
                        <TableHead>
                          <TableRow key={0}>
                            <TableCell>
                              Anexo(s)
                            </TableCell>
                            <TableCell>
                              &nbsp;
                            </TableCell>
                            <TableCell>
                              Ação
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {filesUploaded.map((item) => (
                          <TableBody>
                            <TableRow key={item.caminhoAnexo}>
                              <TableCell align="left" colSpan={2} style={{ fontSize: 10, verticalAlign: 'middle' }}>
                                <span>{item.caminhoAnexo}</span>
                              </TableCell>
                              <TableCell align="left" style={{ fontSize: 10, verticalAlign: 'middle' }}>
                                <CustomDeleteIcon size="small" style={{ marginTop: 0, marginLeft: 4 }} onClick={() => handleRemoveOnListFile(item.caminhoAnexo)} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))}
                      </Table>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <CardActions>
                <Grid container style={{
                  display: 'flex',
                  alignItems: 'end',
                  flexDirection: 'row',
                  textAlign: 'end',
                  justifyContent: 'end'
                }}>
                  <Button
                    variant="outlined"
                    style={{ color: '#000', borderColor: '#000' }}
                    onClick={toggle}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: '#000' }}
                    disabled={props.ordemServico.isLoading}
                    startIcon={<SaveIcon />}
                    endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                  >
                    {props.ordemServico.isLoading ? 'Salvando ordem de serviço...' : 'Salvar'}
                  </Button>
                </Grid>
              </CardActions>
            </ValidatorForm>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog >
  )
}
