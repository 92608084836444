import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { crudService } from '../../_services'
import { toast } from 'react-toastify'
import Typography from '@mui/material/Typography'
import { useConfirm } from 'material-ui-confirm'
import { styled } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';
import CustomButton from '../../components/CustomButton/CustomButton'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Badge,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import {
  authActions,
  empresaAction,
  parametroAction
} from '../../_actions'
import { history, cpfMask, cnpjMask, stringUtils, phoneMask, validateCNPJ, TIPO_USUARIO, MANUAIS_CATEGORIAS } from '../../utils'
import { isEmpty } from 'lodash'
import SaveIcon from '@mui/icons-material/Save';
import PropTypes from 'prop-types';
import { UsuarioEditModal } from './usuarioEditModal.component'
import { HelpModal, EditorHeader } from '../../components';

const SwitchYellow = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#000'
    }
  }
}));

const CustomCardActions = styled(CardActions)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    textAlign: 'center'
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'right',
  [theme.breakpoints.down('md')]: {
    display: 'block',
    textAlign: 'center'
  }
}));

const CustomGridButtonsAction = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'right',
  flexDirection: 'row',
  textAlgin: 'right',
  justifyContent: 'right',
  [theme.breakpoints.down('sm')]: {
    paddingTop: 3,
    display: 'block',
    textAlign: 'center'
  },
}));

const GridButtonAddUser = styled(Grid)(({ theme }) => ({
  justifyContent: 'left',
  [theme.breakpoints.down('md')]: {
    paddingTop: 3,
    display: 'block',
    textAlign: 'center'
  },
}));

const ButtonAdd = styled(Button)({
  backgroundColor: '#F2D647',
  marginLeft: 7,
  marginRight: 7,
  marginBottom: 3,
  '&:hover': {
    backgroundColor: '#F2D647',
    borderColor: '#F2D647',
  },
})

const BadgeShowNames = styled(Badge)({
  fontSize: 15,
  paddingLeft: 7,
  paddingRight: 7,
  color: '#000'
})

export const EmpresaCadastroEdit = ({ props }) => {
  const dispatch = useDispatch()
  const { classes, className, empresa, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const confirm = useConfirm()
  //const { atualizarValorTela } = useContext(ContextModalAjuda);

  const [idOrgao, setIdOrgao] = useState(0);
  const [textOrgao, setTextOrgao] = useState('');
  const [_, forceUpdate] = useState(0);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState(props.empresa.cidade?.nome?.toUpperCase() || '');

  const [modalUsuario, setModalUsuario] = useState(false);
  const [selectedTiposVeiculos, setSelectedTiposVeiculos] = useState([]);
  const [selectedTiposServicos, setSelectedTiposServicos] = useState([]);
  const [valueOrgao, setValueOrgao] = useState(null);
  const [inputValueOrgao, setInputValueOrgao] = useState('');
  const [openOrgao, setOpenOrgao] = useState(false)
  const [options, setOptions] = useState([])

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  };

  const [idOrgaoTeste, setIdOrgaoTeste] = useState(0);

  const toggleUsuario = () => setModalUsuario(!modalUsuario);

  const orgaos = useSelector((state) => state.orgao.orgao)

  const tiposServico = useSelector((state) => state.tipoServico.tipoServico)

  const tiposVeiculo = useSelector((state) => state.tipoVeiculo.tipoVeiculo)

  const estados = useSelector((state) => state.estado.estado)

  const optionsOrgaos = orgaos
    .filter(u => u.value != idOrgaoTeste)
    .map(u => ({ id: u.value, nome: u.text }));

  useEffect(() => {
    if (valueOrgao) {
      handleChangeAutoCompleteOrgao(valueOrgao)
    }
  }, [valueOrgao])

  useEffect(() => {
    if (value) {
      props.empresa.idCidade = Number(value.id)
      props.empresa.cidade = null
      forceUpdate(n => !n)
    }
  }, [value])

  const handleOpenModal = () => {
    toggleUsuario();
  }

  async function fetchIdOrgaoTeste() {
    setIdOrgaoTeste(await dispatch(parametroAction.getIdOrgaoTeste()));
  }

  useEffect(() => {
    fetchIdOrgaoTeste();
  }, [idOrgaoTeste]);

  useEffect(() => {
    if (params.id) {

      if (props.empresa && props.empresa.empresasTiposServicos && props.empresa.empresasTiposServicos.length > 0) {
        setSelectedTiposServicos(props.empresa.empresasTiposServicos)
      }

      if (props.empresa && props.empresa.empresasTiposVeiculos && props.empresa.empresasTiposVeiculos.length > 0) {
        setSelectedTiposVeiculos(props.empresa.empresasTiposVeiculos)
      }

      let apiEndpoint = 'empresas/' + params.id
      crudService
        .get(apiEndpoint)
        .then((response) => {
          if (response.data) {
            setSelectedTiposServicos(response.data.empresasTiposServicos)
            setSelectedTiposVeiculos(response.data.empresasTiposVeiculos)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    //seta automaticamente ao carregar a página 1, caso o valor ainda não exista
    if (!props.empresa?.limiteOrcamentosOrdemServico || props.empresa?.limiteOrcamentosOrdemServico == 0) {
      props.empresa['limiteOrcamentosOrdemServico'] = 1;
    }
  }, [])

  useEffect(() => {
    if (props.empresa.idEstado) {
      let apiEndpoint = 'cidades/select/estado/' + props.empresa.idEstado
      crudService
        .get(apiEndpoint)
        .then((response) => {
          if (response.data) {
            setOptions(response.data?.map(u => ({ id: parseInt(u.value), nome: u.text })))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [props.empresa.idEstado])

  function handleChangeTipoVeiculo(id) {
    const isSelected = selectedTiposVeiculos?.some((n) => n.idTipoVeiculo == id)
    if (isSelected) {
      const newSelected = selectedTiposVeiculos?.filter((n) => n.idTipoVeiculo != id)
      setSelectedTiposVeiculos(newSelected)
    } else {
      let newSelecteds = {
        id: 0,
        idTipoVeiculo: id,
        idEmpresa: props.empresa.id
      }
      setSelectedTiposVeiculos([...selectedTiposVeiculos, newSelecteds])
    }
  }

  function handleChangeTipoServico(id) {
    const isSelected = selectedTiposServicos?.some((n) => n.idTipoServico == id)
    if (isSelected) {
      const newSelected = selectedTiposServicos?.filter((n) => n.idTipoServico != id)
      setSelectedTiposServicos(newSelected)
    } else {
      let newSelecteds = {
        id: 0,
        idTipoServico: id,
        idEmpresa: props.empresa.id
      }
      setSelectedTiposServicos([...selectedTiposServicos, newSelecteds])
    }
  }

  const isTipoVeiculoSelected = (id) => {
    if (id != undefined) {
      return (props.empresa?.empresasTiposVeiculos?.some((n) => n.idTipoVeiculo == id) || selectedTiposVeiculos?.some((n) => n.idTipoVeiculo == id))
    }
  }

  const isTipoServicoSelected = (id) => {
    if (id != undefined) {
      return (props.empresa?.empresasTiposServicos?.some((n) => n.idTipoServico == id) || selectedTiposServicos?.some((n) => n.idTipoServico == id))
    }
  }

  useEffect(() => {
    props.empresa.empresasTiposServicos = selectedTiposServicos;
    forceUpdate(n => !n);
  }, [selectedTiposServicos])

  useEffect(() => {
    props.empresa.empresasTiposVeiculos = selectedTiposVeiculos;
    forceUpdate(n => !n);
  }, [selectedTiposVeiculos])

  const handleAddOrgaoOnList = () => {
    if (props.empresa.empresasOrgaos.filter(ts => ts.idOrgao == parseInt(idOrgao)).length > 0) {
      toast.error('Erro ao adicionar! Este órgao já existe!')
    }
    else {
      props.empresa.empresasOrgaos.push({
        'idOrgao': idOrgao,
        'idEmpresa': props.empresa.id,
        'orgao': {
          'nome': textOrgao
        }
      });
      setIdOrgao(0);
      setValueOrgao(null);
    }
  }

  const handleRemoveOrgaoOnList = idOrgao => {
    props.empresa.empresasOrgaos = props.empresa?.empresasOrgaos?.filter(ts => ts.idOrgao != parseInt(idOrgao));
    forceUpdate(n => !n);
  };

  const handleChangeAutoCompleteOrgao = (obj) => {
    if (obj != null) {
      setIdOrgao(obj.id);
      setTextOrgao(obj.nome);
    }
    setOpenOrgao(false)
  }

  const handleChange = (prop) => (event) => {
    if (event.target.type === 'checkbox') {
      props.empresa[prop] = event.target?.checked
    } else {
      props.empresa[prop] = event.target?.value;
    }
    forceUpdate(n => !n)
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      razaoSocial: props.empresa.razaoSocial,
      nomeFantasia: props.empresa.nomeFantasia,
      cpf: props.empresa.cpf ? props.empresa.cpf.split('.').join('').replace(/[-/.]/g, '') : '',
      cnpj: props.empresa.cnpj ? props.empresa.cnpj.split('.').join('').replace(/[-/.]/g, '') : '',
      celular: props.empresa.celular ? props.empresa.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', '') : '',
      email: props.empresa.email ? props.empresa.email.toLowerCase() : '',
      telefone: props.empresa.telefone ? props.empresa.telefone.split('-').join('').replace('(', '').replace(')', '').replace(' ', '') : '',
      endereco: props.empresa.endereco,
      informacoesCobranca: props.empresa.informacoesCobranca,
      inscricaoEstadual: props.empresa.inscricaoEstadual,
      idCidade: value ? value.id : props.empresa.idCidade,
      idEstado: props.empresa.idEstado,
      usuariosEmpresas: props.empresa.usuariosEmpresas,
      empresasTiposServicos: props.empresa.empresasTiposServicos,
      empresasOrgaos: props.empresa.empresasOrgaos,
      fornecedor: props.empresa.fornecedor,
      prestadorServico: props.empresa.prestadorServico,
      autonomo: props.empresa.autonomo,
      limiteOrcamentosOrdemServico: props.empresa.limiteOrcamentosOrdemServico,
      empresasTiposVeiculos: props.empresa.empresasTiposVeiculos
    }

    if (props.empresa.empresasTiposServicos.length > 0) {
      if (props.empresa.empresasTiposVeiculos.length > 0) {
        if (params.id) {
          dispatch(empresaAction.editEmpresaInfo(params.id, payload, true))
        } else {
          dispatch(empresaAction.createEmpresa(payload))
        }
      } else {
        toast.error('Selecione ao menos um tipo de veículo')
      }
    } else {
      toast.error('Selecione ao menos um tipo de serviço')
    }

  };

  const handleAprovaCadastroEmpresa = () => {
    confirm({
      title: 'Você deseja liberar o acesso ao sistema para esta Empresa?',
      description: 'Caso você se mude de ideia, será necessário recusar o acesso dela posteriormente',
      disabled: props.empresa.isLoading,
      confirmationText: props.empresa.isLoading ? 'Aprovando...' : 'Sim, aprovar!',
    }).then(() => {
      dispatch(empresaAction.editAprovaCadastroEmpresa(params.id))
    })
  }

  const handleRecusaCadastroEmpresa = () => {
    confirm({
      title: 'Você deseja recusar o acesso ao sistema para esta Empresa?',
      description: 'Caso você se mude de ideia, será necessário aprovar o acesso dela posteriormente',
      disabled: props.empresa.isLoading,
      confirmationText: props.empresa.isLoading ? 'Recusando...' : 'Sim, recusar!',
    }).then(() => {
      dispatch(empresaAction.editRecusaCadastroEmpresa(params.id))
    })
  }

  const handleRemoveOnList = id => {
    confirm({
      title: 'Você deseja excluir este usuário?',
      description: 'Essa operação desvinculará o usuário desta empresa',
      disabled: props.empresa.isLoading,
      confirmationText: props.empresa.isLoading ? 'Excluindo usuário...' : 'Sim, excluir!',
    }).then(() => {
      props.empresa.usuariosEmpresas = props.empresa.usuariosEmpresas.filter(ts => ts.idUsuario != parseInt(id));
    })

  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Nova Empresa'}</b>
      </Typography>
    )
  }

  return (
    <Grid item lg={12} md={12} xl={12} xs={12}>
      <Grid item md={12} xs={12}>
        <Card>
          <ValidatorForm
            className={classes.form}
            ref={formRef}
            id="formEmpresa"
            onSubmit={(event) => handleSubmit(event)}
          >
            <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <CardHeader
                subheader="Cadastro de Empresas"
                title={(
                  params.id ?
                    <EditorHeader title={!authActions.isPrefeitura() ? 'Editar Empresa' : 'Visualizar empresa'} tabela='Empresa' chave={params.id} props={props} />
                    :
                    <InsertText />
                )}
              />
              <Grid item lg={1} md={1} xl={1} xs={12}>
                <IconButton
                  aria-label="Ajuda"
                  component="a"
                  onClick={() =>
                    handleOpenModalHelp()
                  }
                  size="small"
                >
                  <Tooltip title={'Ajuda'}>
                    <Tooltip>
                      <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                    </Tooltip>
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <CardContent lg={12} md={12} xl={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="razaoSocial"
                    disabled={authActions.isPrefeitura()}
                    label="Razão Social *"
                    margin="normal"
                    onInput={stringUtils.toInputUppercase}
                    onChange={handleChange('razaoSocial')}
                    value={props.empresa.razaoSocial || ''}
                    variant="outlined"
                    validators={['required', 'maxStringLength: 45']}
                    errorMessages={['Campo obrigatório!', 'Tamanho máximo do campo só pode ser de até 45 caracteres']}
                  />
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="nomeFantasia"
                    label="Nome Fantasia *"
                    disabled={authActions.isPrefeitura()}
                    margin="normal"
                    onInput={stringUtils.toInputUppercase}
                    onChange={handleChange('nomeFantasia')}
                    value={props.empresa.nomeFantasia || ''}
                    variant="outlined"
                    validators={['required', 'maxStringLength: 45']}
                    errorMessages={['Campo obrigatório!', 'Tamanho máximo do campo só pode ser de até 45 caracteres']}

                  />
                </Grid>
                {props.empresa.autonomo != true &&
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      id="cnpj"
                      label="CNPJ *"
                      margin="normal"
                      disabled={(props.empresa.id && props.empresa.cnpj && validateCNPJ(props.empresa.cnpj)) || authActions.isPrefeitura()}
                      onChange={handleChange('cnpj')}
                      value={cnpjMask(props.empresa.cnpj) || ''}
                      variant="outlined"
                      validators={props.empresa.autonomo != true ? ['required'] : null}
                      errorMessages={['Campo obrigatório!']}
                    />
                  </Grid>
                }
                {props.empresa.autonomo == true &&
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      id="cpf"
                      label="CPF *"
                      disabled={authActions.isPrefeitura()}
                      margin="normal"
                      onChange={handleChange('cpf')}
                      value={cpfMask(props.empresa.cpf) || ''}
                      variant="outlined"
                      validators={props.empresa.autonomo == true ? ['required'] : null}
                      errorMessages={['Campo obrigatório!']}
                    />
                  </Grid>
                }
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    type="number"
                    disabled={authActions.isPrefeitura()}
                    id="ie"
                    validators={['maxStringLength: 12']}
                    errorMessages={['Tamanho máximo do campo só pode ser de até 12 caracteres']}
                    label="Inscrição Estadual (Apenas números)"
                    margin="normal"
                    onChange={handleChange('inscricaoEstadual')}
                    value={props.empresa.inscricaoEstadual || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    disabled={authActions.isPrefeitura()}
                    label="E-mail *"
                    onInput={stringUtils.toInputLowerCase}
                    value={props.empresa.email || ''}
                    className={classes.textField}
                    onChange={handleChange('email')}
                    id="email"
                    name="email"
                    autoComplete="email"
                    validators={['required', 'isEmail']}
                    errorMessages={[
                      'Você precisa informar o e-mail',
                      'E-mail inválido'
                    ]}
                  />
                </Grid>
                <Grid item lg={3} md={3} xl={3} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="telefone"
                    label="Telefone *"
                    disabled={authActions.isPrefeitura()}
                    margin="normal"
                    onChange={handleChange('telefone')}
                    value={phoneMask(props.empresa.telefone) || ''}
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={3} md={3} xl={3} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="celular"
                    disabled={authActions.isPrefeitura()}
                    label="Celular *"
                    margin="normal"
                    onChange={handleChange('celular')}
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    value={phoneMask(props.empresa.celular) || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={12}>
                  <SelectValidator
                    className={classes.textField}
                    variant="outlined"
                    id="idEstado"
                    disabled={authActions.isPrefeitura()}
                    label="Estado"
                    value={props.empresa.idEstado}
                    onChange={handleChange('idEstado')}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    displayEmpty
                    labelId="idEstado"
                  >
                    <MenuItem disabled value="">
                      <em>Estado</em>
                    </MenuItem>
                    {!isEmpty(estados) &&
                      undefined !== estados &&
                      estados.length &&
                      estados.map((row, index) => (
                        <MenuItem key={index} value={row.value}>
                          {row.text}
                        </MenuItem>
                      ))}
                  </SelectValidator>
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12} style={{ paddingTop: 31 }}>
                  <Autocomplete
                    autoHighlight
                    value={value}
                    disabled={authActions.isPrefeitura()}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      if (event) {
                        setInputValue(newInputValue);
                      }
                    }}
                    id="controllable-states-demo"
                    options={options}
                    margin="normal"
                    variant="outlined"
                    getOptionLabel={option => option.nome || ''}
                    renderInput={(params) => <TextField {...params} label="Selecione uma cidade..." fullWidth />}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="endereco"
                    disabled={authActions.isPrefeitura()}
                    label="Endereço *"
                    margin="normal"
                    onInput={stringUtils.toInputUppercase}
                    onChange={handleChange('endereco')}
                    value={props.empresa.endereco?.toUpperCase() || ''}
                    variant="outlined"
                    validators={['required', 'maxStringLength: 100']}
                    errorMessages={['Campo obrigatório!', 'Tamanho máximo do campo só pode ser de até 100 caracteres']}
                  />
                </Grid>
                {authActions.isGestor() ?
                  <Grid item lg={2} md={2} xl={2} xs={12}>
                    <Tooltip title="Limite Orçamentos Ordens de Serviço" disableInteractive>
                      <div>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="limiteOrcamentosOrdemServico"
                          label="Limite Orçamentos Ordens de Serviço"
                          margin="normal"
                          type="number"
                          inputProps={{
                            min: 0
                          }}
                          onChange={handleChange('limiteOrcamentosOrdemServico')}
                          value={props.empresa.limiteOrcamentosOrdemServico || ''}
                          variant="outlined"
                        />
                      </div>
                    </Tooltip>
                  </Grid>
                  : null}
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    multiline
                    minRows={3}
                    disabled={authActions.isPrefeitura()}
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="informacoesCobranca"
                    label="Informações de Cobrança"
                    margin="normal"
                    onInput={stringUtils.toInputUppercase}
                    onChange={handleChange('informacoesCobranca')}
                    value={props.empresa.informacoesCobranca || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <FormControlLabel
                    control={
                      <SwitchYellow
                        color="default"
                        disabled={authActions.isPrefeitura()}
                        checked={props.empresa.fornecedor}
                        onChange={handleChange('fornecedor')}
                      />
                    }
                    label="Fornecedor"
                  />
                  <FormControlLabel
                    control={
                      <SwitchYellow
                        color="default"
                        disabled={authActions.isPrefeitura()}
                        checked={props.empresa.prestadorServico}
                        onChange={handleChange('prestadorServico')}
                      />
                    }
                    label="Prestador de Serviço"
                  />
                  <FormControlLabel
                    control={
                      <SwitchYellow
                        color="default"
                        disabled={authActions.isPrefeitura()}
                        checked={props.empresa.autonomo}
                        onChange={handleChange('autonomo')}
                      />
                    }
                    label="Autônomo"
                  />
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <Stack direction="row" spacing={2} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography component="h4" variant="subtitle" align="center">
                      Tipos de veículos atendidos pela empresa *
                    </Typography>
                    {tiposVeiculo && tiposVeiculo != undefined && tiposVeiculo.length > 0 &&
                      tiposVeiculo.map((n) => (
                        <Chip disabled={authActions.isPrefeitura()} key={n.id} label={n.nome} onClick={() => handleChangeTipoVeiculo(n.id)}
                          variant={isTipoVeiculoSelected(n.id) ? "filled" : "outlined"} style={{ backgroundColor: isTipoVeiculoSelected(n.id) && '#F2D647', color: isTipoVeiculoSelected(n.id) && '#000000' }} />
                      ))
                    }
                  </Stack>
                </Grid>
                <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                  <Divider />
                </Grid>
                <Grid item md={12} xs={12} align="center">
                  <Typography component="h4" variant="subtitle" align="center">
                    Tipos de serviços oferecidos pela empresa *
                  </Typography>
                  {tiposServico && tiposServico != undefined && tiposServico.length > 0 &&
                    tiposServico.map((n) => (
                      <Chip disabled={authActions.isPrefeitura()} key={n.id} label={n.nome} onClick={() => handleChangeTipoServico(n.id)}
                        variant={isTipoServicoSelected(n.id) ? "filled" : "outlined"} style={{ backgroundColor: isTipoServicoSelected(n.id) && '#F2D647', color: isTipoServicoSelected(n.id) && '#000000', margin: 5 }} />
                    ))
                  }
                </Grid>
              </Grid>
            </CardContent>
            {params.id && !authActions.isPrefeitura() ?
              <>
                <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                  <Divider />
                </Grid>
                <Grid container lg={12} md={12} xl={12} xs={12} spacing={2}>
                  <Grid container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 7 }} spacing={2} >
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <CustomTypography component="h4" variant="subtitle">
                        Cadastrar e vincular usuário a empresa
                      </CustomTypography>
                    </Grid>
                    {!authActions.isPrefeitura() ?
                      <GridButtonAddUser item lg={4} md={4} xl={4} xs={12}>
                        <Button style={{ backgroundColor: '#000' }}
                          name="btnUsuarios"
                          id="btnUsuarios"
                          className="btn"
                          variant="contained"
                          onClick={() => handleOpenModal()}>Cadastrar usuário&nbsp;<FontAwesomeIcon icon={faUserPlus} />
                        </Button>
                      </GridButtonAddUser>
                      : null
                    }
                  </Grid>
                  <Grid container spacing={2} style={{ marginTop: 6, display: 'flex', flexDirection: 'row', marginLeft: 15, marginRight: 15, justifyContent: 'center' }} align="center">
                    {
                      (props.empresa.usuariosEmpresas != null && props.empresa.usuariosEmpresas.length > 0 ?
                        props.empresa.usuariosEmpresas.map(n => {
                          return (
                            (n.id !== '' && n.excluido != true ?
                              <Grid item lg={3} md={3} xl={3} xs={12} style={{ marginBottom: 2 }} key={n.id}>
                                <Card>
                                  <CardContent>
                                    <Typography variant="h6" component="div">
                                      {(n.usuario ? n.usuario.nome.toUpperCase() : n.idUsuario)}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                      {(n.usuario ? n.usuario.email : '')}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                      {(n.usuario ? n.usuario.idTipoUsuario == TIPO_USUARIO.ADMIN ? 'ADMINISTRADOR' : n.usuario.idTipoUsuario == TIPO_USUARIO.GESTOR_ORGAO ? 'GESTOR ÓRGÃO PÚBLICO' : n.usuario.idTipoUsuario == TIPO_USUARIO.PRESTADOR ? 'PRESTADOR DE SERVIÇO' : n.usuario.idTipoUsuario == TIPO_USUARIO.MOTORISTA ? 'MOTORISTA' : n.usuario.idTipoUsuario == TIPO_USUARIO.FISCAL ? 'FISCAL' : '' : '')}
                                    </Typography>
                                    <Tooltip
                                      title="Excluir usuário"                                          >
                                      <Button disabled={authActions.isPrefeitura()} sx={{ fontWeigth: 400, marginLeft: 7, marginRight: 7 }} onClick={() => handleRemoveOnList(n.idUsuario)} key={'button' + n.id}>
                                        <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#000', fontSize: 17 }} />
                                      </Button>
                                    </Tooltip>
                                  </CardContent>
                                </Card>
                              </Grid>
                              : null)
                          );
                        })
                        : null)
                    }
                  </Grid>
                </Grid>
              </>
              :
              null
            }
            {!authActions.isPrefeitura() ?
              <>
                <Grid item md={12} xs={12} style={{ marginTop: 15, marginBottom: 25 }}>
                  <Divider />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography component="h4" variant="subtitle" align="center">
                      Cidades de Interesse
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center', marginLeft: 20 }} >
                    <Autocomplete
                      disabled={authActions.isPrefeitura()}
                      value={valueOrgao}
                      onChange={(event, newValueOrgao) => {
                        setValueOrgao(newValueOrgao);
                      }}
                      inputValue={inputValueOrgao}
                      onInputChange={(event, newInputValueOrgao) => {
                        setInputValueOrgao(newInputValueOrgao);
                      }}
                      id="controllable-states-demo"
                      options={optionsOrgaos}
                      defaultValue={null}
                      getOptionLabel={option => option?.nome}
                      renderInput={(params) => <TextValidator {...params} label="Selecione uma cidade (órgão) ..." variant="outlined" fullWidth />}
                    />
                  </Grid>
                  {!authActions.isPrefeitura() ?
                    <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                      <Button style={{ width: '200px', marginLeft: '15px', backgroundColor: '#000' }} name="btnOrgaos" id="btnOrgaos" variant="contained" className="btn" component='a' onClick={handleAddOrgaoOnList}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                    </Grid>
                    : null}
                  <Grid item lg={5} md={5} xl={5} xs={12}>
                    <div className="selectgroup selectgroup-pills" style={{ marginLeft: 25, alignItems: 'top' }}>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        {
                          (props.empresa.empresasOrgaos != null && props.empresa.empresasOrgaos.length > 0 ?
                            props.empresa.empresasOrgaos.map(n => {
                              return (
                                (n.id !== '' && n.idOrgao != idOrgaoTeste ?
                                  <ButtonAdd
                                    disabled={authActions.isPrefeitura()}
                                    onClick={() => handleRemoveOrgaoOnList(n.idOrgao)} key={'button' + n.id}>
                                    <BadgeShowNames>
                                      {(n.orgao ? n.orgao.nome : n.idOrgao)}
                                    </BadgeShowNames>
                                    &nbsp;<FontAwesomeIcon
                                      icon={faTrashAlt} style={{ color: '#000' }} />
                                  </ButtonAdd>
                                  : null)
                              );
                            })
                            : null)
                        }
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Typography style={{ fontSize: "10px", paddingLeft: "16px", marginTop: "12px" }}>
                  * Campos obrigatórios
                </Typography>
              </>
              : null
            }
            <CustomCardActions>
              <CustomGridButtonsAction item lg={12} md={12} xl={12} xs={12}>
                <CustomButton
                  variant="outlined"
                  onClick={() => history.push('/empresas')}
                >
                  {authActions.isPrefeitura() ? 'Voltar' : 'Cancelar'}
                </CustomButton>
                {empresa.id && authActions.isGestor() ?
                  <>
                    <CustomButton
                      variant="outlined"
                      dark={false}
                      onClick={handleRecusaCadastroEmpresa}
                      disabled={props.empresa.cadastroLiberado == false || props.empresa.isLoading}
                      startIcon={<CloseIcon />}
                      endIcon={props.empresa.isLoading && <CircularProgress size={24} />}
                    >
                      <Typography variant="p">{props.empresa.cadastroLiberado == false ? 'Acesso recusado' : props.empresa.isLoading ? 'Recusando acesso...' : 'Recusar acesso'}</Typography>
                    </CustomButton>
                    <CustomButton
                      variant="outlined"
                      dark={false}
                      onClick={handleAprovaCadastroEmpresa}
                      disabled={props.empresa.cadastroLiberado == true || props.empresa.isLoading}
                      startIcon={<DoneIcon />}
                      endIcon={props.empresa.isLoading && <CircularProgress size={24} />}
                    >
                      {props.empresa.cadastroLiberado == true ? 'Acesso liberado' : props.empresa.isLoading ? 'Liberando acesso...' : 'Liberar acesso'}
                    </CustomButton>
                  </>
                  : null}
                {!authActions.isPrefeitura() ?
                  <CustomButton
                    type="submit"
                    variant="contained"
                    dark={true}
                    disabled={props.empresa.isLoading}
                    startIcon={<SaveIcon />}
                    endIcon={props.empresa.isLoading && <CircularProgress size={24} />}
                  >
                    {params.id ? (props.empresa.isLoading ? 'Atualizando empresa...' : 'Atualizar') : (props.empresa.isLoading ? 'Salvando empresa...' : 'Salvar')}
                  </CustomButton>
                  : null
                }
              </CustomGridButtonsAction>
            </CustomCardActions>
          </ValidatorForm>
        </Card>
      </Grid >
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.CADASTRO_EMPRESA_ADMIN}
          />
          :
          null
        )
      }

      {
        (modalUsuario ?
          <UsuarioEditModal toggle={toggleUsuario} modal={modalUsuario} props={props} idempresa={params.id} />
          :
          null
        )
      }


    </Grid >
  )
}

EmpresaCadastroEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
