export const stringUtils = {
  serialize,
  toInputUppercase,
  toInputLowerCase,
  sanitizeHTML
};

function serialize(obj) {
  let str = '?' + Object.keys(obj).reduce(function (a, k) {
    a.push(k + '=' + encodeURIComponent(obj[k]));
    return a;
  }, []).join('&');
  return str;
}

function toInputUppercase(e) {
  var start = e.target.selectionStart;
  var end = e.target.selectionEnd;
  e.target.value = e.target.value.toUpperCase();
  e.target.setSelectionRange(start, end);
};

function toInputLowerCase(e) {
  var start = e.target.selectionStart;
  var end = e.target.selectionEnd;
  e.target.value = e.target.value.toLowerCase();
  e.target.setSelectionRange(start, end);
};

function sanitizeHTML(html) {
  // Substitui <div>, <p> e outras tags específicas por uma tag segura ou por nada.
  let sanitizedHTML = html;

  sanitizedHTML = sanitizedHTML.replace(/<div>/g, ' ').replace(/<\/div>/g, ' ');
  sanitizedHTML = sanitizedHTML.replace(/<p>/g, ' ').replace(/<\/p>/g, ' ');
  sanitizedHTML = sanitizedHTML.replace(/<br>/g, ' '); // Substitui <br> por espaço
  sanitizedHTML = sanitizedHTML.replace(/<\/?script[^>]*>/g, ' '); // Remove qualquer <script> (para evitar XSS)

  return sanitizedHTML;
};
