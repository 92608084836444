import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { PDFViewer, Document, Page, View, Text, StyleSheet } from '@react-18-pdf/renderer';
import { Header, Footer } from '../../_reports';
import { placaMask, stringUtils } from '../../utils'
import { styled } from '@mui/material/styles';
import moment from 'moment'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    fontSize: 7,
    fontFamily: 'Roboto-Regular',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1
  },
  header: {
    borderRight: 1,
    padding: 5,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center'
  },
  cell: {
    borderRight: 1,
    padding: 3,
    textAlign: 'center'
  }
});

const CustomPage = styled(Page)(({ theme }) => ({
  padding: 20,
  paddingBottom: 25,
  height: '100%',
  width: '100%'
}));

const RelatorioOrcamentosOrdemServicoPrint = (props) => {

  const [ready, setReady] = React.useState(false);
  const { match: { params } } = props;
  const { relatorio } = props.relatorio
  
  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 1100);
  }, [])

  const onRendered = () => {
  };

  return (
    (ready &&
      <div style={{ width: '100%', height: '100%' }}>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document onRender={onRendered} width='100%'>
            <CustomPage orientation="landscape" size="A4" style={styles.page} wrap={true} >
              <Header
                title='Relatório de Orçamentos Recebidos para a Ordem de Serviço'
                info={<Text>ROTOR - FABRICAÇÃO, MANUTENÇÃO, REPARAÇÃO E FORNECIMENTO LTDA - 40.691.238/0001-25</Text>}
              />
              <View style={[styles.row, { marginTop: 60 }]} key={0} >
                <View style={[styles.header, { width: 850 }]}>
                  <Text>DETALHES DA ORDEM DE SERVIÇO</Text>
                </View>
              </View>
              <View style={[styles.row, { marginTop: 0 }]} key={0} >
                <View style={[styles.header, { width: 50 }]}>
                  <Text>ID</Text>
                </View>
                <View style={[styles.header, { width: 200 }]}>
                  <Text>TÍTULO</Text>
                </View>
                <View style={[styles.header, { width: 200 }]}>
                  <Text>DESCRITIVO</Text>
                </View>
                <View style={[styles.header, { width: 100 }]}>
                  <Text>TIPO DE SERVIÇO</Text>
                </View>
                <View style={[styles.header, { width: 150 }]}>
                  <Text>VEÍCULO</Text>
                </View>
                <View style={[styles.header, { width: 150 }]}>
                  <Text>ÓRGÃO</Text>
                </View>
              </View>
              <View style={[styles.row]} key={props.ordemServico.id} >
                <View style={[styles.cell, { width: 50 }]}>
                  <Text>{props.ordemServico.id}</Text>
                </View>
                <View style={[styles.cell, { width: 200 }]}>
                  <Text>{props.ordemServico.titulo?.toUpperCase()}</Text>
                </View>
                <View style={[styles.cell, { width: 200 }]}>
                  <Text>{props.ordemServico.descritivo ? stringUtils.sanitizeHTML(props.ordemServico.descritivo).toUpperCase() : ''}</Text>
                </View>
                <View style={[styles.cell, { width: 100 }]}>
                  <Text>{props.ordemServico.tipoServico?.nome?.toUpperCase()}</Text>
                </View>
                <View style={[styles.cell, { width: 150 }]}>
                  <Text>{placaMask(props.ordemServico.veiculo?.placa)}</Text>
                </View>
                <View style={[styles.cell, { width: 150 }]}>
                  <Text>{props.ordemServico.orgao?.nome?.toUpperCase()}</Text>
                </View>
              </View>
              <View style={[styles.row, { marginTop: 10 }]} key={0} >
                <View style={[styles.header, { width: 850 }]}>
                  <Text>ORÇAMENTO APROVADO PARA A ORDEM DE SERVIÇO</Text>
                </View>
              </View>
              <View style={[styles.row, { marginTop: 0 }]} key={0} >
                <View style={[styles.header, { width: 100 }]}>
                  <Text>DATA</Text>
                </View>
                <View style={[styles.header, { width: 100 }]}>
                  <Text>PRAZO</Text>
                </View>
                <View style={[styles.header, { width: 250 }]}>
                  <Text>EMPRESA</Text>
                </View>
                <View style={[styles.header, { width: 100 }]}>
                  <Text>VALOR</Text>
                </View>
                <View style={[styles.header, { width: 300 }]}>
                  <Text>OBSERVAÇÕES</Text>
                </View>
              </View>
              {
                relatorio.length > 0 ? relatorio.map(n => {
                  var retorno = (
                    n.statusOrcamento == 1 &&
                    <View style={[styles.row]} key={n.id} >
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{n.data ? moment(n.data).format('DD/MM/yyyy') : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{n.prazo ? moment(n.prazo).format('DD/MM/yyyy') : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 250 }]}>
                        <Text>{n.empresa ? n.empresa.toUpperCase() : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text> {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 300 }]}>
                        <Text>{n.observacoes ? n.observacoes.toUpperCase() : ''}</Text>
                      </View>
                    </View>
                  );
                  return retorno;
                })
                  : <View style={[styles.row]} key={0} >
                    <View style={[styles.cell, { width: 850 }]}>
                      <Text>Nenhum registro encontrado</Text>
                    </View>
                  </View>
              }
              <View style={[styles.row, { marginTop: 10 }]} key={0} >
                <View style={[styles.header, { width: 850 }]}>
                  <Text>ORÇAMENTO(S) RECUSADO(S) PARA A ORDEM DE SERVIÇO</Text>
                </View>
              </View>
              <View style={[styles.row, { marginTop: 0 }]} key={0} >
                <View style={[styles.header, { width: 100 }]}>
                  <Text>DATA</Text>
                </View>
                <View style={[styles.header, { width: 100 }]}>
                  <Text>PRAZO</Text>
                </View>
                <View style={[styles.header, { width: 250 }]}>
                  <Text>EMPRESA</Text>
                </View>
                <View style={[styles.header, { width: 100 }]}>
                  <Text>VALOR</Text>
                </View>
                <View style={[styles.header, { width: 300 }]}>
                  <Text>OBSERVAÇÕES</Text>
                </View>
              </View>
              {
                relatorio.length > 0 && relatorio.some(r => r.statusOrcamento === 2 || r.statusOrcamento === null) ? relatorio.map(n => {
                  var retorno = (
                    (n.statusOrcamento == 2 || n.statusOrcamento == null) &&
                    <View style={[styles.row]} key={n.id} >
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{n.data ? moment(n.data).format('DD/MM/yyyy') : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{n.prazo ? moment(n.prazo).format('DD/MM/yyyy') : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 250 }]}>
                        <Text>{n.empresa ? n.empresa.toUpperCase() : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text> {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 300 }]}>
                        <Text>{n.observacoes ? n.observacoes.toUpperCase() : ''}</Text>
                      </View>
                    </View>
                  );
                  return retorno;
                })
                  : <View style={[styles.row]} key={0} >
                    <View style={[styles.cell, { width: 850 }]}>
                      <Text>Nenhum registro encontrado</Text>
                    </View>
                  </View>
              }

              <View style={[styles.row, { marginTop: 10 }]} key={0} >
                <View style={[styles.header, { width: 850 }]}>
                  <Text>ORÇAMENTO(S) DECLINADO(S) PARA A ORDEM DE SERVIÇO</Text>
                </View>
              </View>
              <View style={[styles.row, { marginTop: 0 }]} key={0} >
                <View style={[styles.header, { width: 100 }]}>
                  <Text>DATA</Text>
                </View>
                <View style={[styles.header, { width: 250 }]}>
                  <Text>EMPRESA</Text>
                </View>
                <View style={[styles.header, { width: 500 }]}>
                  <Text>OBSERVAÇÕES</Text>
                </View>
              </View>
              {
                relatorio.length > 0 && relatorio.some(r => r.statusOrcamento === 2 || r.statusOrcamento === null) ? relatorio.map(n => {
                  var retorno = (
                    n.declinio == true &&
                    <View style={[styles.row]} key={n.id} >
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{n.data ? moment(n.data).format('DD/MM/yyyy') : ''}</Text>
                      </View>                      
                      <View style={[styles.cell, { width: 250 }]}>
                        <Text>{n.empresa ? n.empresa.toUpperCase() : ''}</Text>
                      </View>                     
                      <View style={[styles.cell, { width: 500 }]}>
                        <Text>{n.observacoes ? n.observacoes.toUpperCase() : ''}</Text>
                      </View>
                    </View>
                  );
                  return retorno;
                })
                  : <View style={[styles.row]} key={0} >
                    <View style={[styles.cell, { width: 850 }]}>
                      <Text>Nenhum registro encontrado</Text>
                    </View>
                  </View>
              }
              <Footer />
            </CustomPage>
          </Document>
        </PDFViewer>
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return state;
};

const connectedRelatorioOrcamentosOrdemServicoPrintPage = withRouter(connect(mapStateToProps, null, null)(RelatorioOrcamentosOrdemServicoPrint));
export { connectedRelatorioOrcamentosOrdemServicoPrintPage as RelatorioOrcamentosOrdemServicoPrint };
