import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { authActions, ordemServicoAction } from '../../_actions'
import { styled } from '@mui/material/styles';
import { MANUAIS_CATEGORIAS, placaMask, stringUtils } from '../../utils'
import PropTypes from 'prop-types'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import moment from 'moment'
import {
  Avatar,
  Button,
  Fade,
  Grid,
  IconButton,
  Input,
  Paper,
  Skeleton,
  TablePagination,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: '13px !important'
  }
})

const SpanStyled = styled('span')({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center'
});

const GridStyled = styled(Grid)(() => ({
  marginBottom: 8,
  padding: 2
}));

const AvatarStyled = styled(Avatar)(() => ({
  width: 143,
  height: 103,
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  backgroundSize: 'cover',
}));

const ButtonStyled = styled(Button)(() => ({
  paddingLeft: 5,
  paddingTop: 2,
  paddingBottom: 2,
  paddingRight: 5,
  backgroundColor: '#000000',
  color: '#FFF',
  '&:hover': {
    backgroundColor: '#000',
    transition: '0.2s'
  },
}));

const ToolTipFormatted = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 550,
    fontSize: 9,
    border: '1.2px solid #000',
  },
}));

const OrdemServicoAguardandoOrcamento = (props) => {

  const dispatch = useDispatch()
  const { classes } = props
  const { ordemServico } = props.ordemServico
  const { totalRegistros } = props.ordemServico
  const { isLoading } = props.ordemServico

  const [status, setStatus] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');
  const [ready, setReady] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: idOrgao,
        Status: 2
      };

      dispatch(ordemServicoAction.getOrdemServico(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idOrgao]);

  useEffect(() => {
    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        IdOrgao: idOrgao,
        Direction: direction,
        Status: 2
      };
      dispatch(ordemServicoAction.getOrdemServico(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [status])

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.ordemServico.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Ordens de Serviço Aguardando Orçamento">
      <AccessControl
        rule={'ordensServicoOrcamentos.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== ordemServico && ordemServico.length > 0 ? (
              <TitleContent
                length={ordemServico.length}
                subTitle={'GERÊNCIA DE ORÇAMENTOS'}
                title={'Lista de Ordens de Serviços Aguardando Orçamentos'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_ORDEM_AGUARDANDO_ORCAMENTO}
              />
            ) : (
              <TitleContent
                length={0}
                subTitle={'GERÊNCIA DE ORÇAMENTOS'}
                title={'Lista de Ordens de Serviços Aguardando Orçamentos'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_ORDEM_AGUARDANDO_ORCAMENTO}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Título</TableCell>
                    <TableCell>Descritivo</TableCell>
                    <TableCell>Veículo</TableCell>
                    <TableCell>Órgão</TableCell>
                    <TableCell align="right">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== ordemServico && ordemServico.length
                    ? ordemServico.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.id ? n.id : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              {n.emergencia ? (
                                <Tooltip title="Ordem de serviço emergencial">
                                  <WarningAmberIcon sx={{ color: '#ef5350', fontSize: '1.5em', marginRight: 0.5, verticalAlign: 'middle' }} />
                                </Tooltip>
                              ) : null}
                              {n.garantia ? (
                                <Tooltip title="Exige garantia">
                                  <WorkspacePremiumOutlinedIcon sx={{ fontSize: '1.5em', marginRight: 0.5, verticalAlign: 'middle' }} />
                                </Tooltip>
                              ) : null}
                              <span style={{ verticalAlign: 'middle' }}>{n.titulo ? n.titulo.toUpperCase() : ''}</span>
                            </div>
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.descritivo ? stringUtils.sanitizeHTML(n.descritivo) : ''}
                          </TableCell>
                          <ToolTipFormatted
                            placement="top-end"
                            TransitionComponent={Fade}
                            title={
                              <GridStyled container>
                                <Grid item lg={12} md={12} xl={12} xs={12}>
                                  <Typography color="inherit" align="center"><b>Detalhes do Veículo</b></Typography>
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid item lg={4} md={4} xl={4} xs={12} align="center">
                                    {n.veiculo?.caminhoFoto ?
                                      <AvatarStyled variant="rounded"
                                        src={n.veiculo.caminhoFoto}
                                      />
                                      :
                                      <AvatarStyled variant="rounded">
                                        <DirectionsCarIcon sx={{ fontSize: 40 }} />
                                      </AvatarStyled>
                                    }
                                  </Grid>
                                  <Grid item lg={8} md={8} xl={8} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <SpanStyled><Typography sx={{ fontSize: 12 }} color="inherit" align="left"><b>Placa:</b>&nbsp;{n.veiculo ? placaMask(n.veiculo.placa) : ''}</Typography></SpanStyled>
                                    <SpanStyled><Typography sx={{ fontSize: 12 }} color="inherit" align="left"><b>Ano:</b>&nbsp;{n.veiculo?.ano}</Typography></SpanStyled>
                                    <SpanStyled><Typography sx={{ fontSize: 12 }} color="inherit" align="left"><b>Marca:</b>&nbsp;{n.veiculo?.marca?.nome?.toUpperCase()}</Typography></SpanStyled>
                                    <SpanStyled><Typography sx={{ fontSize: 12 }} color="inherit" align="left"><b>Modelo:</b>&nbsp;{n.veiculo?.modelo?.toUpperCase()}</Typography></SpanStyled>
                                    <SpanStyled><Typography sx={{ fontSize: 12 }} color="inherit" align="left"><b>Renavam:</b>&nbsp;{n.veiculo?.renavam ? n.veiculo.renavam : '-'}</Typography></SpanStyled>
                                    <SpanStyled><Typography sx={{ fontSize: 12 }} color="inherit" align="left"><b>Hodômetro:</b>&nbsp;{n.veiculo?.hodometro ? n.veiculo.hodometro : '-'}</Typography></SpanStyled>
                                  </Grid>
                                </Grid>
                                {authActions.isGestor() &&
                                  <Grid item lg={12} md={12} xl={12} xs={12} align="center" className={classes.textField}>
                                    <ButtonStyled onClick={() => history.push(`/veiculo/` + n.veiculo.id)} size="small">Ver mais</ButtonStyled>
                                  </Grid>
                                }
                              </GridStyled>
                            }
                          >
                            <TableCell component="th" scope="row" align="right" className={classes.textField}>
                              {n.veiculo ? placaMask(n.veiculo.placa) : ''}
                            </TableCell>
                          </ToolTipFormatted>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.orgao ? n.orgao.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'ordensServicoOrcamentos.create'} //permissão necessária para acessar conteúdo
                              yes={() =>
                              (
                                <IconButton
                                  aria-label="Enviar orçamento"
                                  component="a"
                                  onClick={() => history.push(`/ordensServicoOrcamento/`, n.id)}
                                  size="small"
                                >
                                  <Tooltip title={'Enviar orçamento'}>
                                    <Tooltip >
                                      <SendIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )
                              }
                            />
                          </TableCell>
                        </TableRow >
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div >
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
OrdemServicoAguardandoOrcamento.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
}
const mapStateToProps = (state) => {
  return {
    ordemServico: state.ordemServico
  }
}
const connectedOrdemServicoAguardandoOrcamentoPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(OrdemServicoAguardandoOrcamento))
)
export { connectedOrdemServicoAguardandoOrcamentoPage as OrdemServicoAguardandoOrcamento }
