import React from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import CustomButton from '../../components/CustomButton/CustomButton'
import moment from 'moment'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { ordemServicoAction } from '../../_actions'
import {
  ValidatorForm
} from 'react-material-ui-form-validator'
import logoMecanicoDelivery from '../../img/mecanicoDelivery.svg'
import logoRotor from '../../img/rotor_sem_fundo.png'
import { stringUtils } from '../../utils'

export const RedeCredenciadaOuMecanicoDeliveryModal = ({ modal, toggle, props, orgao, tipoServico, veiculo }) => {

  const formRef = React.createRef(null);

  const dispatch = useDispatch()
  const { classes, className, ordemServico, ...rest } = props
  const currentDate = moment().format("yyyy-MM-DD");
  const { match: { params } } = props;

  const handleClose = () => {
    toggle();
  }

  const handleSubmit = (event) => {

    let payload = {
      id: props.ordemServico.id,
      idTipoServico: tipoServico.value,
      idVeiculo: veiculo.value,
      idUsuario: props.authentication.user.id,
      data: props.ordemServico.data ? props.ordemServico.data : currentDate,
      idOrgao: orgao.value,
      descritivo: props.ordemServico.descritivo,
      titulo: props.ordemServico.titulo,
      ultimoEvento: props.ordemServico.ultimoEvento,
      emergencia: props.ordemServico.emergencia,
      ordensServicosFotos: props.ordemServico.ordensServicosFotos
    }

    if (payload) {
      dispatch(ordemServicoAction.editOrdemServicoAprova(props.ordemServico.id))
      dispatch(ordemServicoAction.editOrdemServicoInfo(payload, props.ordemServico.id))
      toggle();
    }

  };

  const handleSendMessageMecanicoDelivery = (props) => {

    var cel = '5551996181518';

    var titulo = props.ordemServico.titulo;
    var descritivo = stringUtils.sanitizeHTML(props.ordemServico.descritivo);

    var texto = "Olá, gostaria de agendar o serviço: \n\n" +
      "------- ORDEM DE SERVIÇO ------- \n" +
      "TÍTULO: " + titulo + "\n" +
      "DESCRITIVO: " + descritivo + "\n" +
      "ÓRGÃO: " + orgao.text + "\n" +
      "TIPO DE SERVIÇO: " + (tipoServico ? tipoServico.text : 'NÃO INFORMADO') + "\n" +
      "VEÍCULO: " + (veiculo ? veiculo.text : 'NÃO INFORMADO');

    texto = window.encodeURIComponent(texto);

    window.open('https://api.whatsapp.com/send?phone=' + cel + '&text=' + texto, '_blank')

    if (tipoServico) {
      let payload = {
        id: props.ordemServico.id,
        idTipoServico: tipoServico.value,
        idVeiculo: veiculo.value,
        idUsuario: props.authentication.user.id,
        data: props.ordemServico.data ? props.ordemServico.data : currentDate,
        idOrgao: orgao.value,
        descritivo: props.ordemServico.descritivo,
        titulo: props.ordemServico.titulo,
        ultimoEvento: props.ordemServico.ultimoEvento,
        emergencia: props.ordemServico.emergencia
      }

      if (payload) {
        dispatch(ordemServicoAction.editOrdemServicoMecanicoDelivery(payload, props.ordemServico.id))
        toggle();
      }
    } else {
      toast.error('Informe um tipo de serviço')
    }
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Salvar Ordem de Serviço'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formOs"
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Cadastro de Ordem de Serviço"
              title={<SegHeader />}
            />
            <Divider />
            <CardContent lg={12} md={12} xl={12} xs={12}>
              <Typography>
                <strong>Selecione abaixo qual fluxo a ordem de serviço deve seguir:</strong>
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
                <Typography style={{
                  fontSize: 13
                }}>
                  <strong>REDE CREDENCIADA:</strong> Seguirá para o recebimento de orçamentos.
                </Typography>
                <Typography style={{
                  fontSize: 13
                }}>
                  <strong>MECÂNICO DELIVERY:</strong> Seguirá direto para a execução do serviço.
                </Typography>
              </div>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <CustomButton
                  variant="outlined"
                  onClick={toggle}
                >
                  Cancelar
                </CustomButton>
                <CustomButton
                  type="submit"
                  variant="outlined"
                  style={{ color: '#000' }}
                  onClick={handleSubmit}
                  startIcon={<img src={logoRotor} width={25} />}
                  endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                >
                  Enviar para rede credenciada
                </CustomButton>
                <CustomButton
                  type="submit"
                  variant="contained"
                  dark={true}
                  onClick={() => handleSendMessageMecanicoDelivery(props)}
                  startIcon={<img src={logoMecanicoDelivery} width={25} />}
                  endIcon={props.usuario.isLoading && <CircularProgress size={24} />}
                >
                  Enviar para o mecânico delivery
                </CustomButton>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}
